const theme = {
    fgColor01: '#666',
    bgColor01: 'white',

    fgColor02: '#666',
    bgColor02: '#eee',

    fgColor03: '#666',
    bgColor03: '#ddd',

    fgColor04: '#333',
    bgColor04: '#ccc',

    buyColorChart: '#1890ff44',
    sellColorChart: "#ff222244",

    buyColor: '#1890ff88',
    sellColor: "#ff222288",

    winColor: 'darkgreen',
    loseColor: "#ff222288",
}

export default theme
