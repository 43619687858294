/**
 * @flow
 */
"use strict";

import {barsGet} from "./TradingViewByBitBarsGetter";

export type Exchange = {|
  value: string,
  name: string,
  desc: string,
|};

export type SymbolType =
  | "stock"
  | "index"
  | "forex"
  | "futures"
  | "bitcoin"
  | "crypto"
  | "undefined"
  | "expression"
  | "spread"
  | "cfd"
  | "economic"
  | "equity"
  | "dr"
  | "bond"
  | "right"
  | "warrant"
  | "fund";

export type Resolution =
  | "1"
  | "3"
  | "5"
  | "15"
  | "30"
  | "60"
  | "120"
  | "240"
  | "360"
  | "720"
  | "1D";
// | "1W"
// | "1M"

export type DatafeedSymbolType = {|
  name: string,
  value: string,
|};

export type ConfigurationData = {|
  exchanges?: Array<Exchange>,
  symbols_types?: Array<DatafeedSymbolType>,
  supported_resolutions: Array<Resolution>,
  currency_codes?: Array<"USD" | "EUR" | "GBP">,
  supports_marks?: boolean,
  supports_timescale_marks?: boolean,
  supports_time?: boolean,
|};

export type SearchSymbolsResult = Array<{|
  symbol: string,
  full_name: string, // e.g. BTCE:BTCUSD
  description: string,
  exchange: string,
  ticker?: string,
  type: SymbolType,
|}>;

export type SymbolInfo = {|
  name: string,
  full_name: string,
  ticker: string,
  description: string,
  type: SymbolType,
  session: "24x7",
  timezone: "Etc/UTC",
  format: "price" | "volume",
  exchange: string,
  minmov: number,
  minmov2: number,
  fractional: boolean,
  pricescale: number,
  has_intraday?: boolean,
  has_no_volume?: boolean,
  has_seconds?: boolean,
  has_empty_bars?: boolean,
  has_weekly_and_monthly?: boolean,
  supported_resolutions: Array<Resolution>,
  volume_precision: number,
  data_status: "streaming" | "endofday" | "pulsed" | "delayed_streaming",
  expired?: boolean,
|};

export type Bar = {|
  time: number, // Amount of milliseconds since Unix epoch start in UTC timezone. time for daily bars is expected to be a trading day (not session start day) at 00:00 UTC. Charting Library adjusts time according to Session from SymbolInfo. time for monthly bars is the first trading day of the month without the time part.
  open: number, // Bar's open value
  high: number, // Bar's high value
  low: number, // Bar's low value
  close: number, // Bar's close value
  volume?: number, //  Bar's volume value
|};

export type MetaInformation = {|
  noData: boolean, // This flag should be set if there is no data in the requested period.
  nextTime?: ?number, // unix timestamp (UTC). Time of the next bar in the history. It should be set if the requested period represents a gap in the data. Hence there is available data prior to the requested period.
|};

export type SubscriberUID = string;

export type DatafeedProps = {|
  configurationData: ConfigurationData,
  symbols: Map<string, SymbolInfo>,
  unsubscribeBarUpdates: ({|
    id: string,
  |}) => any,
  subscribeBarUpdates: ({|
    id: string,
    symbol: string,
    resolution: Resolution,
    callback: (Bar) => void,
  |}) => any,
|};

export class Datafeed {
  props: DatafeedProps;

  constructor(props: DatafeedProps) {
    this.props = props;
  }

  onReady: ((ConfigurationData) => void) => void = (callback) => {
    setTimeout(() => callback(this.props.configurationData));
  };

  searchSymbols: (
    string,
    string,
    string,
    (SearchSymbolsResult) => void,
  ) => void = (userInput, exchange, symbolType, onResultReadyCallback) => {
    // const symbols = await getAllSymbols();
    // const newSymbols = symbols.filter((symbol) => {
    //   const isExchangeValid = exchange === "" || symbol.exchange === exchange;
    //   const isFullSymbolContainsInput =
    //     symbol.full_name.toLowerCase().indexOf(userInput.toLowerCase()) !== -1;
    //   return isExchangeValid && isFullSymbolContainsInput;
    // });
    // onResultReadyCallback(newSymbols);
    onResultReadyCallback([]);
  };

  resolveSymbol: (
    string,
    (SymbolInfo) => void,
    (string) => void,
    ?{currencyCode?: string},
  ) => void = (
    symbolName,
    onSymbolResolvedCallback,
    onResolveErrorCallback,
    extension,
  ) => {
    const symbolInfo = this.props.symbols.get(symbolName);
    if (symbolInfo) {
      setTimeout(() => onSymbolResolvedCallback(symbolInfo), 0);
    } else {
      onResolveErrorCallback("resolveSymbol: symbol not found");
    }
  };

  getBars: (
    SymbolInfo,
    Resolution,
    number,
    number,
    (Array<Bar>, MetaInformation) => void,
    (string) => void,
    boolean,
  ) => Promise<void> = async (
    symbolInfo,
    resolution,
    from,
    to,
    onHistoryCallback,
    onErrorCallback,
    firstDataRequest,
  ) => {
    let formatFrom = Math.floor(from / 60) * 60;
    const formatTo = Math.floor(to / 60) * 60;
    try {
      const bars = await barsGet({
        ticker: symbolInfo.ticker,
        resolution,
        from: formatFrom,
        to: formatTo,
      });
      onHistoryCallback(bars, {noData: bars.length === 0});
    } catch (e) {
      console.error("getBars: api failure", e);
      onErrorCallback("getBars: api failure");
    }
  };

  subscribeBars: (
    SymbolInfo,
    Resolution,
    (Bar) => void,
    SubscriberUID,
    () => void,
  ) => void = (
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscriberUID,
    onResetCacheNeededCallback,
  ) => {
    this.props.subscribeBarUpdates({
      id: subscriberUID,
      symbol: symbolInfo.ticker,
      resolution,
      callback: (i) => {
        onRealtimeCallback(i);
      },
    });
  };

  unsubscribeBars: (SubscriberUID) => void = (subscriberUID) => {
    this.props.unsubscribeBarUpdates({
      id: subscriberUID,
    });
  };
}

export const loadInterval = (saveKey: string): Resolution => {
  // $FlowFixMe
  return localStorage.getItem("tv_chart_interval_" + saveKey) || "1D";
};

export const saveInterval = (saveKey: string, value: Resolution): void => {
  localStorage.setItem("tv_chart_interval_" + saveKey, value);
};

export const loadSetting = (saveKey: string): Object => {
  const item = localStorage.getItem("tv_chart_settings_" + saveKey);
  return item ? JSON.parse(item) : null;
};

export const saveSetting = (saveKey: string, tvWidget: any): void => {
  if (saveKey) {
    tvWidget.save((e) => {
      const c = e.charts[0];
      const data = {
        charts: [
          {
            panes: c.panes,
            timeScale: c.timeScale,
          },
        ],
      };
      localStorage.setItem(
        "tv_chart_settings_" + saveKey,
        JSON.stringify(data),
      );
    });
  }
};

export const createMarkPriceStudy = (
  tvWidget: any,
  markPriceSymbol: string,
) => {
  const studyName = "Compare"; // Esto no se puede cambiar.
  let studyFound = !!tvWidget
    .chart()
    .getAllStudies()
    .find(
      (i) =>
        i.name === studyName &&
        tvWidget
          .chart()
          .getStudyById(i.id)
          ?.getInputValues()
          .find((i) => i.value.indexOf(markPriceSymbol) !== -1),
    );
  if (!studyFound) {
    tvWidget
      .chart()
      .createStudy(studyName, false, false, ["close", markPriceSymbol], {
        "Plot.linewidth": 2,
        "Plot.color": "#ffb11a",
      })
      .then((i) => {
        tvWidget
          .chart()
          .getStudyById(i)
          .bringToFront();
        tvWidget
          .chart()
          .getPanes()[0]
          .getRightPriceScales()[0]
          .setMode(0);
      });
  }
};
