/**
 * @flow
 */
"use strict";

import React, {Component, PropTypes} from "react";
import actions from "../actions";
import * as api2 from "../api2";
import * as types from "../actions/types";
import {ThemeContext} from "styled-components";
import {Panel, OrderButton, OrderSizeButton} from "./StyledComponents";

export type Props = {
  orderBook: types.OrderBook,
};

type State = {};

export default class MyComponent extends React.Component<Props, State> {
  // static defaultProps = {}

  constructor(props: Props) {
    super(props);
    // this.state = {};
  }

  render(): React$Node {
    const {orderBook} = this.props;
    if (orderBook.bids.length === 0 && orderBook.asks.length === 0) {
      return null;
    }
    const maxDepth = Math.max(orderBook.bidsDepth, orderBook.asksDepth);
    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <Panel style={styles.cFlexAuto}>
            <div style={styles.rFlex}>
              <div style={{...styles.headerText, flex: 0.5}}>{"Bids"}</div>
              <div
                style={{
                  ...styles.headerText,
                  flex: 1,
                  textAlign: "center",
                  fontFamily: "monospace",
                }}
              >
                {`${orderBook.bestBuyAmount.toFixed(
                  1,
                )} | ${orderBook.bestSellAmount.toFixed(1)}`}
              </div>
              <div
                style={{...styles.headerText, flex: 0.5, textAlign: "right"}}
              >
                {"Asks"}
              </div>
            </div>
            <div style={{...styles.rFlex, fontFamily: "monospace"}}>
              <div style={styles.cFlex}>
                {orderBook.bids.slice(0, 10).map((i) => (
                  <div
                    key={i.id}
                    style={{
                      ...styles.rFlex,
                      paddingRight: 10,
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        top: 0,
                        bottom: 0,
                        right: 0,
                        width:
                          ((i.cumulative * 100) / maxDepth).toFixed(0) + "%",
                        position: "absolute",
                        backgroundColor: theme?.buyColorChart,
                      }}
                    />
                    <div style={{flex: 1, zIndex: 1}}>{i.cumulative}</div>
                    <div style={{flex: 1, zIndex: 1, textAlign: "right"}}>
                      {i.price}
                    </div>
                  </div>
                ))}
              </div>
              <div style={styles.cFlex}>
                {orderBook.asks.slice(0, 10).map((i) => (
                  <div
                    key={i.id}
                    style={{
                      ...styles.rFlex,
                      paddingLeft: 10,
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        top: 0,
                        bottom: 0,
                        left: 0,
                        width:
                          ((i.cumulative * 100) / maxDepth).toFixed(0) + "%",
                        position: "absolute",
                        backgroundColor: theme?.sellColorChart,
                      }}
                    />
                    <div style={{flex: 1, zIndex: 1}}>{i.price}</div>
                    <div style={{flex: 1, zIndex: 1, textAlign: "right"}}>
                      {i.cumulative}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Panel>
        )}
      </ThemeContext.Consumer>
    );
  }
}

const styles = {
  cFlex: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  cFlexAuto: {
    display: "flex",
    flexDirection: "column",
  },
  rFlex: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
  },
  rFlexAuto: {
    display: "flex",
    flexDirection: "row",
  },
  labelText: {
    flex: 1,
  },
  itemText: {
    flex: 2,
    textAlign: "right",
  },
  headerText: {
    fontSize: "1.2rem",
    marginBottom: "0.5rem",
  },
  orderButton: {
    padding: "3rem",
    fontSize: "2rem",
  },
};
