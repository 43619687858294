/**
 * @flow
 */

// import * as url from "url";

export type Dictionary<T> = {[index: string]: T};
export type FetchAPI = {(url: string, init?: any): Promise<any>};

const BASE_PATH = "https://xxxxxxxxxxx/api".replace(/\/+$/, "");
const isomorphicFetch = fetch;

export type FetchArgs = {|
  url: string,
  options: any,
|};

export class Configuration {
  apiKey: {|
    Authorization: string,
    Authorization: string,
  |};
  username: ?string;
  password: ?string;
  accessToken: ?string;
  headers: {[key: string]: string};
}

export class BaseAPI {
  basePath: string;
  fetch: FetchAPI;
  configuration: Configuration;

  constructor(
    fetch: FetchAPI = isomorphicFetch,
    basePath: string = BASE_PATH,
    configuration: Configuration = new Configuration(),
  ) {
    this.basePath = basePath;
    this.fetch = fetch;
    this.configuration = configuration;
  }
}

/**
 * DefaultApi - fetch parameter creator
 */
export const DefaultApiFetchParamCreator: Object = {};

/**
 * DefaultApi - functional programming interface
 */
export const DefaultApiFp: Object = {};

/**
 * DefaultApi - object-oriented interface
 */
export class DefaultApi extends BaseAPI {}
