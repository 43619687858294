/**
 * @flow
 */


import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import type { Store, MyDispatch, Action } from '../actions/types.js';
import actions from '../actions';
import * as api2 from '../api2';
import MyComponent, {type Props} from '../components/AddStopOrderPanel';


type OwnProps = {|
|}

const connector = connect<Props, OwnProps, _, _, _, _>(
  (store: Store, ownProps: OwnProps) => ({
      settings: store.user.tradingSettings,
      account: store.bybit.account,
  }),
  (dispatch: MyDispatch, ownProps: OwnProps) => ({
    updateTradingSettings: bindActionCreators(
      actions.user.updateTradingSettings,
      dispatch,
    ),
    placeStopMarketOrder: bindActionCreators(
      actions.bybit.placeStopMarketOrder,
      dispatch,
    ),
  }),
);

const Connected: * = connector(MyComponent);
export default Connected;

function test(props: OwnProps) {
  return <Connected {...props} />;
}


