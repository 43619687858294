import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import configure from "./configure";
import sagas from "./sagas";
import reducers from "./reducers";

const ConfiguredApp = configure({
  App,
  Loading: <div>Loading ...</div>,
  reducers,
  sagas,
});

ReactDOM.render(
  <React.StrictMode>
    <ConfiguredApp />
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
