/**
 * @flow
 */
"use strict";

import React, {Component, PropTypes} from "react";
import actions from "../actions";
import * as types from "../actions/types";
import * as api2 from "../api2";
import styled, {ThemeContext} from "styled-components";
import {calculateStopLoss} from "../lib/ByBitUtils";

const options: Array<?number> = [null, 0, 25, 50, 75, 100];

const StopLossPercentageButton = styled.button`
  padding: 0.1rem;
  width: 2.8rem;
  margin: 0.1rem;
  background-color: ${(props) => (props.selected ? "lightgrey" : "darkgrey")};
  border: none;
`;

export type Props = {|
  updateTradingSettings: typeof actions.user.updateTradingSettings,
  setTradingStop: typeof actions.bybit.setTradingStop,
  account: types.ByBitAccountState,
  settings: types.TradingSettings,
|};

type State = {};

export default class MyComponent extends React.Component<Props, State> {
  // static defaultProps = {}

  constructor(props: Props) {
    super(props);
    // this.state = {};
  }

  render(): React$Node {
    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <div style={{flexDirection: "row"}}>
            {options.map((i) => (
              <StopLossPercentageButton
                key={i}
                onClick={() =>
                  this.props.updateTradingSettings({autoStopLossPercentage: i})
                }
                selected={this.props.settings.autoStopLossPercentage === i}
              >
                {i === null
                  ? "Off"
                  : i === 0
                  ? "No SL"
                  : "-" + (i?.toFixed(0) || "") + "%"}
              </StopLossPercentageButton>
            ))}
          </div>
        )}
      </ThemeContext.Consumer>
    );
  }
}

const styles = {
  container: {
    flex: 1,
  },
  buttonSelected: {},
  button: {},
};
