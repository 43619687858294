/**
 * @flow
 */
"use strict";

import type {Bar, Resolution} from "./TradingViewUtils";

const DB_VERSION = 2;
type BarsCacheProps = {|
  ticker: string,
  resolution: Resolution,
|};

export class BarsCache {
  props: BarsCacheProps;

  constructor(props: BarsCacheProps) {
    this.props = props;
  }

  getDBName(): string {
    return ["TradingViewBarsCache", this.props.ticker].join("-");
  }

  getDB(): Promise<any> {
    return new Promise((resolve, cancel) => {
      const DBName = this.getDBName();
      const DBOpenRequest = window.indexedDB.open(DBName, DB_VERSION);
      DBOpenRequest.onerror = function(event) {
        console.error("Error opening " + DBName, event);
        cancel("Error opening " + DBName);
      };
      DBOpenRequest.onsuccess = function(event) {
        resolve(DBOpenRequest.result);
      };
      DBOpenRequest.onupgradeneeded = function(event) {
        const db = event.target.result;
        db.onerror = function(event) {
          console.error("Error loading database " + DBName, event);
          cancel("Error loading database " + DBName);
        };
        [
          "1",
          "3",
          "5",
          "15",
          "30",
          "60",
          "120",
          "240",
          "360",
          "720",
          "1D",
          "1W",
          "1M",
        ].forEach((resolution) => {
          const objectStore = db.createObjectStore(resolution, {
            keyPath: "time",
          });
          objectStore.createIndex("time", "time", {unique: true});
        });
      };
    });
  }

  getTransaction(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getDB().then((db) => {
        const objectStoreName = this.props.resolution;
        const transaction = db.transaction([objectStoreName], "readwrite");
        transaction.oncomplete = function() {};
        transaction.onerror = function(error) {
          // no podemos hacer reject aca porque ya hicimos resolve.
          console.error("transaction error:", objectStoreName, error);
        };
        const objectStore = transaction.objectStore(objectStoreName);
        resolve(objectStore);
      });
    });
  }

  get({from, to}: {from: number, to: number}): Promise<Array<Bar>> {
    return new Promise((resolve, cancel) => {
      this.getTransaction().then((objectStore) => {
        const bars = [];
        const index = objectStore.index("time");
        // $FlowFixMe.
        const query = IDBKeyRange.bound(from * 1000, to * 1000, false, false);
        index.openCursor(query).onsuccess = function(event) {
          var cursor = event.target.result;
          if (cursor) {
            const bar: Bar = cursor.value;
            bar && bars.push(bar);
            cursor.continue();
          } else {
            resolve(bars);
          }
        };
      });
    });
  }

  set(bars: Array<Bar>): void {
    this.getTransaction().then((objectStore) => {
      // console.log("adding", bars.length, "bars to cache");
      bars.forEach((i) => objectStore.add(i));
    });
  }

  clear(): void {
    this.getTransaction().then((objectStore) => objectStore.clear());
  }
}
