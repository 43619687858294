// @flowjs

import styled from "styled-components";

export const Panel = styled.div`
  background-color: ${(props) => props.theme.bgColor04};
  color: ${(props) => props.theme.fgColor04};
  font-size: 0.9rem;
  padding: 1rem;
  margin: 1rem;
  margin: 1rem;
`;

export const OrderButton = styled.button`
  font-family: monospace;
  font-size: 0.9rem;
  padding: 0.3rem 1.5rem;
  margin-bottom: 0.2rem;
  color: ${(props) => props.theme.fgColor04};
  background-color: ${(props) =>
    props.side === "Buy"
      ? props.theme.buyColorChart
      : props.theme.sellColorChart};
`;

export const OrderSizeButton = styled.button`
  padding: 0.1rem;
  width: 2.8rem;
  margin: 0.1rem;
  background-color: darkgrey;
  border: none;
`;
