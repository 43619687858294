/**
 * @flow
 */

import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import type {Store, MyDispatch, Action} from "../actions/types.js";
import actions from "../actions";
import * as api2 from "../api2";
import MyComponent, {type Props} from "../components/TradingViewChart";
import {buildOrderBook, buildOpenOrdersInfo} from "../lib/ByBitUtils";

type OwnProps = {||};

const connector = connect<Props, OwnProps, _, _, _, _>(
  // $FlowFixMe.
  (store: Store, ownProps: OwnProps) => {
    const orderBook = buildOrderBook(store.bybit.account.orderBook, 10);
    const openOrdersInfo = buildOpenOrdersInfo(store.bybit.account, orderBook);
    return {
      settings: store.user.tradingSettings,
      account: store.bybit.account,
      book: orderBook,
      orders: openOrdersInfo.sortedOrders,
      stopOrders: openOrdersInfo.stopOrders,
      position: store.bybit.account.positions.get(store.bybit.account.symbol),
      positionExtraInfo: store.bybit.account.positionsExtraInfo.get(store.bybit.account.symbol),
    };
  },
  (dispatch: MyDispatch, ownProps: OwnProps) => ({
    placeLimitPostOnlyOrder: bindActionCreators(
      actions.bybit.placeLimitPostOnlyOrder,
      dispatch,
    ),
    subscribeBarUpdates: bindActionCreators(
      actions.bybit.subscribeBarUpdates,
      dispatch,
    ),
    unsubscribeBarUpdates: bindActionCreators(
      actions.bybit.unsubscribeBarUpdates,
      dispatch,
    ),
    updateTradingSettings: bindActionCreators(
      actions.user.updateTradingSettings,
      dispatch,
    ),
  }),
);

const Connected: * = connector(MyComponent);
export default Connected;

function test(props: OwnProps) {
  return <Connected {...props} />;
}
