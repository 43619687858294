/**
 * @flow
 */
"use strict";

import React, {Component, PropTypes} from "react";
import actions from "../actions";
import * as api2 from "../api2";
import * as types from "../actions/types";
import {ThemeContext} from "styled-components";
import {Panel, OrderButton, OrderSizeButton} from "./StyledComponents";
import SettingsInputField from "../containers/SettingsInputFieldContainer";
import OrderGroupSelector from "../containers/OrderGroupSelectorContainer";
import AutoStopLossSelector from "../containers/AutoStopLossSelectorContainer";

export type Props = {|
  account: types.ByBitAccountState,
  orderBook: types.OrderBook,
  settings: types.TradingSettings,
  openOrdersInfo: types.OpenOrdersInfo,
  placeLimitPostOnlyOrder: typeof actions.bybit.placeLimitPostOnlyOrder,
  placeMarketOrder: typeof actions.bybit.placeMarketOrder,
  replaceOrder: typeof actions.bybit.replaceOrder,
  updateTradingSettings: typeof actions.user.updateTradingSettings,
|};

type State = {};

function shortenBigNumber(number: number): string {
  if (number >= 1000000) {
    return (number / 1000000).toFixed(0) + "M";
  } else if (number >= 1000) {
    return (number / 1000).toFixed(0) + "K";
  } else {
    return number.toFixed(0);
  }
}

export default class MyComponent extends React.Component<Props, State> {
  // static defaultProps = {}

  constructor(props: Props) {
    super(props);
    // this.state = {};
  }

  placeLimitOrder(params: $Shape<types.bybitapi.PlaceOrderRequest>) {
    const orderSize = parseInt(this.props.settings.orderSize);
    const autoOrderSize = this.props.settings.autoOrderSize
      ? 1 + parseInt(this.props.settings.autoOrderSize) / 100
      : 0;
    this.props.placeLimitPostOnlyOrder({
      ...params,
      symbol: this.props.account.symbol,
      qty: orderSize,
    });
    if (autoOrderSize) {
      this.props.updateTradingSettings({
        orderSize: Math.ceil(orderSize * autoOrderSize).toFixed(0),
      });
    }
  }

  placeMarketOrder(params: $Shape<types.bybitapi.PlaceOrderRequest>) {
    this.props.placeMarketOrder({
      ...params,
      symbol: this.props.account.symbol,
      qty: parseInt(this.props.settings.orderSize),
    });
  }

  moveOrders(side: "Buy" | "Sell", direction: -1 | 1) {
    const orders = this.props.openOrdersInfo.sortedOrders.filter(
      (i) =>
        i.side === side &&
        i.order_link_id.endsWith("_" + this.props.settings.orderGroup),
    );
    const curMap: Map<string, Array<types.bybitws.Order>> = new Map();
    const newMap: Map<string, Array<types.bybitws.Order>> = new Map();
    let step = this.props.settings.stepSize
      ? parseInt(this.props.settings.stepSize)
      : 0;
    if (!step) {
      return;
    }

    // Move orders the smaller between the step or the gap between the best
    // order and current price.
    // Otherwise we would be just executing or cancelling the orders when step
    // is larger than the gap.
    let gap: ?number = null;
    if (side === "Buy" && direction === 1 && this.props.orderBook.bestBuy) {
      gap =
        parseFloat(this.props.orderBook.bestBuy.price) -
        parseFloat(orders[0]?.price || "0");
    } else if (
      side === "Sell" &&
      direction === -1 &&
      this.props.orderBook.bestSell
    ) {
      gap = Math.abs(
        parseFloat(this.props.orderBook.bestSell.price) -
          parseFloat(orders[0]?.price || "0"),
      );
    }
    if (gap != null && gap < step) {
      step = gap;
    }

    if (step <= 0) {
      // Ya esta al borde.
      return;
    }

    orders.forEach((i) => {
      const curKey = parseFloat(i.price).toFixed(1) + "-" + i.qty;
      const curArr = curMap.get(curKey) || [];
      curArr.push(i);
      curMap.set(curKey, curArr);
      const newKey =
        (parseFloat(i.price) + step * direction).toFixed(1) + "-" + i.qty;
      const newArr = newMap.get(newKey) || [];
      newArr.push(i);
      newMap.set(newKey, newArr);
    });
    Array.from(newMap.entries()).forEach(([key, newArr]) => {
      const curArr = curMap.get(key) || [];
      const moved = curArr.splice(0, newArr.length);
      newArr.splice(0, moved.length);
      if (newArr.length === 0) newMap.delete(key);
      if (curArr.length === 0) curMap.delete(key);
    });
    // Sanity check.
    const curOrders = Array.from(curMap.values()).flat();
    const newOrders = Array.from(newMap.values()).flat();
    if (curOrders.length !== newOrders.length) {
      console.log("Order lists do not match", curOrders, newOrders);
      return;
    }
    // /Sanity check.
    Array.from(newMap.entries()).forEach(([key, newArr]) => {
      Array.from({length: newArr.length}, (x, y) => {
        const price = key.split("-")[0];
        const qty = parseInt(key.split("-")[1]);
        const idx = curOrders.findIndex((i) => i.qty === qty);
        if (idx !== -1) {
          const order = curOrders.splice(idx, 1)[0];
          this.props.replaceOrder(
            {
              symbol: order.symbol,
              order_id: order.order_id,
              order_link_id: order.order_link_id,
              p_r_price: price,
            },
            {
              ...order,
              price,
            },
          );
        } else {
          console.log("error moving order of key", key, "could not find match");
        }
      });
    });
  }

  render(): React$Node {
    if (
      !this.props.orderBook.bestBuyAmount ||
      !this.props.orderBook.bestSellAmount
    ) {
      return null;
    }
    const step = this.props.settings.stepSize
      ? parseInt(this.props.settings.stepSize)
      : null;
    const size = this.props.settings.orderSize
      ? parseInt(this.props.settings.orderSize)
      : null;
    const ordersBestSellAmount =
      this.props.openOrdersInfo.bestSellAmount.get(
        this.props.settings.orderGroup,
      ) || this.props.orderBook.bestSellAmount;
    const ordersBestBuyAmount =
      this.props.openOrdersInfo.bestBuyAmount.get(
        this.props.settings.orderGroup,
      ) || this.props.orderBook.bestBuyAmount;
    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <Panel>
            {this.props.settings.dangerousEnabled ? (
              <div style={{float: "right"}}>
                <OrderGroupSelector groupCount={3} />
              </div>
            ) : null}
            <div style={styles.headerText}>{"Place Order"}</div>
            <div style={{...styles.rFlex, justifyContent: "space-between"}}>
              <div style={styles.cFlexAuto}>
                {size && this.props.settings.dangerousEnabled ? (
                  <OrderButton
                    side={"Buy"}
                    onClick={() =>
                      this.placeMarketOrder({
                        side: "Buy",
                      })
                    }
                  >
                    {`Buy ${size} @`}<br/>{`MARKET`}
                  </OrderButton>
                ) : null}
                {size ? (
                  <OrderButton
                    side={"Buy"}
                    onClick={() =>
                      this.placeLimitOrder({
                        side: "Buy",
                        price: this.props.orderBook.bestBuyAmount,
                      })
                    }
                  >
                    {`Buy ${size} @`}<br/>{`${this.props.orderBook.bestBuyAmount.toFixed(
                      2,
                    )}`}
                  </OrderButton>
                ) : null}
                {size && step && ordersBestBuyAmount
                  ? [1].map((i) => (
                      <OrderButton
                        key={
                          "Buy_" + this.props.orderBook.bestBuyAmount + "_" + i
                        }
                        side={"Buy"}
                        onClick={() =>
                          this.placeLimitOrder({
                            side: "Buy",
                            price: ordersBestBuyAmount - step * i,
                          })
                        }
                      >
                        {`Buy ${size} @`}<br/>{`${ordersBestBuyAmount.toFixed(
                          2,
                        )} - ${step * i}`}
                      </OrderButton>
                    ))
                  : null}
                <div style={{margin: "0.5rem 0rem", textAlign: "center"}}>
                  {"Open "}
                  <span
                    style={{
                      color: theme?.buyColor,
                      fontWeight: "bold",
                    }}
                  >
                    {this.props.openOrdersInfo.totalBuy.toFixed(0)}
                  </span>
                  {this.props.openOrdersInfo.averageBuy ? (
                    <span>
                      {" "}
                      @ {this.props.openOrdersInfo.averageBuy.toFixed(2)}
                      <br />
                      {this.props.openOrdersInfo.projectedBuyPnL != null ? (
                        <span>
                          {this.props.openOrdersInfo.projectedBuyPnL.toFixed(
                            2,
                          ) + "%"}
                          <br />
                        </span>
                      ) : (
                        <br />
                      )}
                    </span>
                  ) : (
                    <span>
                      <br />
                      <br />
                    </span>
                  )}
                </div>
                <div style={{textAlign: "center"}}>
                  <button
                    style={{marginRight: "0.5rem"}}
                    onClick={() => this.moveOrders("Buy", -1)}
                  >
                    ▼
                  </button>
                  <button onClick={() => this.moveOrders("Buy", 1)}>▲</button>
                </div>
              </div>
              <div style={{textAlign: "center", marginTop: "-2rem"}}>
                <table>
                  <tbody>
                    <tr>
                      <td>Size</td>
                      <td>
                        <SettingsInputField
                          keyName={"orderSize"}
                          save={true}
                          adapter={(txt) => txt.replace(/\D/, "")}
                          style={{width: 50}}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <div style={{width: "6rem", margin: "0.20rem 0"}}>
                          {[10, 50, 100, 200, 500, 1000].map((i) => {
                            i =
                              i *
                              Math.pow(
                                10,
                                this.props.settings.orderSizeSelectorPage,
                              );
                            return (
                              <OrderSizeButton
                                key={i}
                                onClick={() =>
                                  this.props.updateTradingSettings({
                                    orderSize: i.toFixed(0),
                                  })
                                }
                              >
                                {shortenBigNumber(i)}
                              </OrderSizeButton>
                            );
                          })}
                          <OrderSizeButton
                            onClick={() =>
                              this.props.updateTradingSettings({
                                orderSizeSelectorPage: Math.max(
                                  this.props.settings.orderSizeSelectorPage - 1,
                                  0,
                                ),
                              })
                            }
                          >
                            {"<<"}
                          </OrderSizeButton>
                          <OrderSizeButton
                            onClick={() =>
                              this.props.updateTradingSettings({
                                orderSizeSelectorPage: Math.min(
                                  this.props.settings.orderSizeSelectorPage + 1,
                                  3,
                                ),
                              })
                            }
                          >
                            {">>"}
                          </OrderSizeButton>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Incr.</td>
                      <td>
                        <SettingsInputField
                          keyName={"autoOrderSize"}
                          save={true}
                          adapter={(txt) => txt.replace(/\D/, "")}
                          style={{width: 40}}
                        />
                        %
                      </td>
                    </tr>
                    <tr>
                      <td>Step</td>
                      <td>
                        <SettingsInputField
                          keyName={"stepSize"}
                          save={true}
                          adapter={(txt) => txt.replace(/\D/, "")}
                          style={{width: 50}}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <div style={{width: "6rem", margin: "0.20rem 0"}}>
                          <OrderSizeButton
                            onClick={() =>
                              this.props.updateTradingSettings({
                                stepSize: Math.ceil(
                                  parseInt(this.props.settings.stepSize) / 2,
                                ).toFixed(0),
                              })
                            }
                          >
                            {"<<"}
                          </OrderSizeButton>
                          <OrderSizeButton
                            onClick={() =>
                              this.props.updateTradingSettings({
                                stepSize: Math.ceil(
                                  parseInt(this.props.settings.stepSize) * 2,
                                ).toFixed(0),
                              })
                            }
                          >
                            {">>"}
                          </OrderSizeButton>
                        </div>
                        {this.props.settings.dangerousEnabled ? (
                          <div style={{width: "6rem"}}>
                            <div>Auto stop loss</div>
                            <AutoStopLossSelector />
                          </div>
                        ) : null}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div style={styles.cFlexAuto}>
                {size && this.props.settings.dangerousEnabled ? (
                  <OrderButton
                    side={"Sell"}
                    onClick={() =>
                      this.placeMarketOrder({
                        side: "Sell",
                      })
                    }
                  >
                    {`Sell ${size} @`}<br/>{`MARKET`}
                  </OrderButton>
                ) : null}
                {size ? (
                  <OrderButton
                    side={"Sell"}
                    onClick={() =>
                      this.placeLimitOrder({
                        side: "Sell",
                        price: this.props.orderBook.bestSellAmount,
                      })
                    }
                  >
                    {`Sell ${size} @`}<br/>{`${this.props.orderBook.bestSellAmount.toFixed(
                      2,
                    )}`}
                  </OrderButton>
                ) : null}
                {size && step && ordersBestSellAmount
                  ? [1].map((i) => (
                      <OrderButton
                        key={
                          "Sell_" +
                          this.props.orderBook.bestSellAmount +
                          "_" +
                          i
                        }
                        side={"Sell"}
                        onClick={() =>
                          this.placeLimitOrder({
                            side: "Sell",
                            price: ordersBestSellAmount + step * i,
                          })
                        }
                      >
                        {`Sell ${size} @`}<br/>{`${ordersBestSellAmount.toFixed(
                          2,
                        )} + ${step * i}`}
                      </OrderButton>
                    ))
                  : null}
                <div style={{margin: "0.5rem 0rem", textAlign: "center"}}>
                  {"Open "}
                  <span
                    style={{
                      color: theme?.sellColor,
                      fontWeight: "bold",
                    }}
                  >
                    {this.props.openOrdersInfo.totalSell.toFixed(0)}
                  </span>
                  {this.props.openOrdersInfo.averageSell ? (
                    <span>
                      {" "}
                      @ {this.props.openOrdersInfo.averageSell.toFixed(2)}
                      <br />
                      {this.props.openOrdersInfo.projectedSellPnL != null ? (
                        <span>
                          {this.props.openOrdersInfo.projectedSellPnL.toFixed(
                            2,
                          ) + "%"}
                          <br />
                        </span>
                      ) : (
                        <br />
                      )}
                    </span>
                  ) : (
                    <span>
                      <br />
                      <br />
                    </span>
                  )}
                </div>
                <div style={{textAlign: "center"}}>
                  <button
                    style={{marginRight: "0.5rem"}}
                    onClick={() => this.moveOrders("Sell", -1)}
                  >
                    ▼
                  </button>
                  <button onClick={() => this.moveOrders("Sell", 1)}>▲</button>
                </div>
              </div>
            </div>
          </Panel>
        )}
      </ThemeContext.Consumer>
    );
  }
}

const styles = {
  cFlex: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  cFlexAuto: {
    display: "flex",
    flexDirection: "column",
  },
  rFlex: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
  },
  rFlexAuto: {
    display: "flex",
    flexDirection: "row",
  },
  labelText: {
    flex: 1,
  },
  itemText: {
    flex: 2,
    textAlign: "right",
  },
  headerText: {
    fontSize: "1.2rem",
    marginBottom: "0.5rem",
  },
  orderButton: {
    padding: "3rem",
    fontSize: "2rem",
  },
};
