const theme = {
    fgColor01: '#777',
    bgColor01: '#101014',

    fgColor02: '#999',
    bgColor02: '#17181e',

    fgColor03: '#aaa',
    bgColor03: '#3d3e46',

    fgColor04: '#999',
    bgColor04: '#28292e',

    buyColorChart: '#1890ff44',
    sellColorChart: "#ff222244",

    buyColor: '#1890ff88',
    sellColor: "#ff222288",

    winColor: 'darkgreen',
    loseColor: "#ff222288",
}

export default theme