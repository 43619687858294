/**
 * @flow
 */

import * as types from "../actions/types";
import actions from "../actions";

type _State = {|
  +account: types.ByBitAccountState,
  +newOrders: number,
  +failedOrders: number,
|};
export type State = _State & $Shape<_State>;

function newOrderBook(): types.ByBitOrderBook {
  return {
    Buy: new Map(),
    Sell: new Map(),
  };
}

function copyOrderBook(orderBook: types.ByBitOrderBook): types.ByBitOrderBook {
  return {
    Buy: new Map(orderBook.Buy),
    Sell: new Map(orderBook.Sell),
  };
}

const round = (x: number, y: number): number =>
  Math.round(x * Math.pow(10, y)) / Math.pow(10, y);

export const initialState: State = {
  account: {
    symbol: "BTCUSD",
    apiKey: "",
    apiSecret: "",
    orderBook: newOrderBook(),
    positions: new Map(),
    positionsExtraInfo: new Map(),
    orders: new Map(),
    stopOrders: new Map(),
    wallets: new Map(),
    pendingOrders: new Map(),
    instrumentInfo: {
      symbol: "BTCUSD",
      last_price_e4: 0,
      last_tick_direction: "PlusTick",
      prev_price_24h_e4: 0,
      price_24h_pcnt_e6: 0,
      high_price_24h_e4: 0,
      low_price_24h_e4: 0,
      prev_price_1h_e4: 0,
      price_1h_pcnt_e6: 0,
      mark_price_e4: 0,
      index_price_e4: 0,
      open_interest: 0,
      open_value_e8: 0,
      total_turnover_e8: 0,
      turnover_24h_e8: 0,
      total_volume: 0,
      volume_24h: 0,
      funding_rate_e6: 0,
      predicted_funding_rate_e6: 0,
      cross_seq: 0,
      created_at: "",
      updated_at: "",
      next_funding_time: "",
      countdown_hour: 0,
    },
  },
  newOrders: 0,
  failedOrders: 0,
};

export default function reducer(
  state: State = initialState,
  action: types.Action,
): State {
  // Usamos if en vez de switch porque no nos permite definir variables
  // con el mismo nombre en los distintos case.
  if (action.type === "BYBIT_START_CONNECTION_REQUEST") {
    return {
      newOrders: 0,
      failedOrders: 0,
      account: {
        ...initialState.account,
        symbol: action.symbol,
        apiKey: action.apiKey,
        apiSecret: action.apiSecret,
        orderBook: newOrderBook(),
      },
    };
  } else if (action.type === "BYBIT_REPLACE_ORDER_REQUEST") {
    let {account} = state;
    if (account) {
      const pendingOrders = new Map(state.account?.pendingOrders);
      pendingOrders.set(action.request.order_link_id, {
        side: action.order.side,
        qty: action.order.qty,
        order_type: action.order.order_type,
        price: parseFloat(action.order.price),
      });
      return {
        ...state,
        account: {
          ...account,
          pendingOrders,
        },
      };
    } else {
      return state;
    }
  } else if (action.type === "BYBIT_REPLACE_ORDER_FAILED") {
    let {account} = state;
    if (account) {
      const pendingOrders = new Map(state.account?.pendingOrders);
      pendingOrders.delete(action.request.order_link_id);
      return {
        ...state,
        account: {
          ...account,
          pendingOrders,
        },
      };
    } else {
      return state;
    }
  } else if (action.type === "BYBIT_PLACE_ORDER_REQUEST") {
    let {account} = state;
    if (account) {
      const pendingOrders = new Map(state.account?.pendingOrders);
      pendingOrders.set(action.request.order_link_id, action.request);
      return {
        ...state,
        account: {
          ...account,
          pendingOrders,
        },
      };
    } else {
      return state;
    }
  } else if (action.type === "BYBIT_PLACE_ORDER_FAILED") {
    let {account} = state;
    if (account) {
      const pendingOrders = new Map(state.account?.pendingOrders);
      pendingOrders.delete(action.request.order_link_id);
      return {
        ...state,
        account: {
          ...account,
          pendingOrders,
        },
      };
    } else {
      return state;
    }
  } else if (action.type === "BYBIT_INSTRUMENT_INFO_SNAPSHOT_RECEIVED") {
    return {
      ...state,
      account: {
        ...state.account,
        instrumentInfo:
          action.data.symbol === state.account.symbol
            ? action.data
            : state.account.instrumentInfo,
      },
    };
  } else if (action.type === "BYBIT_INSTRUMENT_INFO_DELTA_RECEIVED") {
    const delta = action.update.find((i) => i.symbol === state.account.symbol);
    return {
      ...state,
      account: {
        ...state.account,
        instrumentInfo: {
          ...state.account.instrumentInfo,
          ...delta,
        },
      },
    };
  } else if (action.type === "BYBIT_ORDER_BOOK_SNAPSHOT_RECEIVED") {
    let {account} = state;
    if (account) {
      const orderBook = newOrderBook();
      action.orders.forEach((i) => orderBook[i.side].set(i.id, i));
      return {
        ...state,
        account: {
          ...account,
          orderBook,
        },
      };
    } else {
      return state;
    }
  } else if (action.type === "BYBIT_ORDER_BOOK_DELTA_RECEIVED") {
    let {account} = state;
    if (!account) {
      return state;
    }
    const orderBook = copyOrderBook(account.orderBook);
    action.insert.forEach((i) => orderBook[i.side].set(i.id, i));
    action.update.forEach((i) => orderBook[i.side].set(i.id, i));
    action.delete.forEach((i) => orderBook[i.side].delete(i.id));
    return {
      ...state,
      account: {
        ...account,
        orderBook,
      },
    };
  } else if (action.type === "BYBIT_STOP_ORDERS_UPDATE") {
    const stopOrders = new Map(state.account?.stopOrders);
    action.orders.forEach((i) => {
      const order = stopOrders.get(i.order_id);
      if (!order || order.timestamp < i.timestamp) {
        stopOrders.set(i.order_id, i);
      }
    });
    return {
      ...state,
      account: {
        ...state.account,
        stopOrders,
      },
    };
  } else if (action.type === "BYBIT_ORDERS_UPDATE") {
    let {account} = state;
    if (!account) {
      return state;
    }
    let newOrders = 0;
    let failedOrders = 0;
    const orders = new Map(state.account?.orders);
    const pendingOrders = new Map(state.account?.pendingOrders);
    action.orders.forEach((i) => {
      let order = orders.get(i.order_id);
      if (pendingOrders.has(i.order_link_id)) {
        if (i.order_status === "Cancelled") {
          // Orden que fallaron al crearse/modificarse. Usualmente post-only,
          // pero no sabemos si va a impactar en otros casos.
          failedOrders += 1;
        } else {
          // Orden nueva/reemplazada local. No tomamos ordenes dadas de alta
          // desde otras terminales.
          newOrders += 1;
        }
      }
      if (!order || order.timestamp < i.timestamp) {
        orders.set(i.order_id, i);
      }
      pendingOrders.delete(i.order_link_id);
    });
    return {
      ...state,
      newOrders,
      failedOrders,
      account: {
        ...account,
        orders,
        pendingOrders,
      },
    };
  } else if (action.type === "BYBIT_POSITIONS_UPDATE") {
    let {account} = state;
    if (!account) {
      return state;
    }
    const positions = new Map(state.account?.positions);
    action.positions.forEach((i) => positions.set(i.symbol, i));
    return {
      ...state,
      account: {
        ...account,
        positions,
      },
    };
  } else if (action.type === "BYBIT_POSITION_EXTRA_INFO_UPDATE") {
    let {account} = state;
    if (!account) {
      return state;
    }
    const position = new Map(state.account?.positions).get(action.symbol);
    const positionsExtraInfo = new Map(state.account?.positionsExtraInfo);
    if (
      position &&
      position.size > 0 &&
      action.sizeValidation === position.size
    ) {
      positionsExtraInfo.set(action.symbol, {
        realizedPnL: action.realizedPnL,
        entryPriceValidation: parseFloat(position.entry_price),
        sizeValidation: action.sizeValidation,
        executions: action.executions,
        breakEvenPrice:
          position.size /
          (position.size / parseFloat(position.entry_price) +
            action.realizedPnL * (position.side === "Sell" ? -1 : 1)),
      });
    } else {
      positionsExtraInfo.delete(action.symbol);
    }
    return {
      ...state,
      account: {
        ...account,
        positionsExtraInfo,
      },
    };
  } else if (action.type === "BYBIT_ORDERS_EXECUTION_UPDATE") {
    let {account} = state;
    if (!account) {
      return state;
    }
    const positions = new Map(state.account?.positions);
    const positionsExtraInfo = new Map(state.account?.positionsExtraInfo);
    action.executions.forEach((i) => {
      const position = positions.get(i.symbol);
      const positionExtraInfo = positionsExtraInfo.get(i.symbol);
      const executions = [...(positionExtraInfo?.executions || []), i];
      if (position) {
        if (position.size === 0) {
          // New position.
          console.log("new position");
          positionsExtraInfo.set(i.symbol, {
            realizedPnL: 0,
            entryPriceValidation: parseFloat(i.price),
            sizeValidation: i.exec_qty,
            breakEvenPrice: parseFloat(i.price),
            executions,
          });
        } else if (positionExtraInfo) {
          if (position.size !== positionExtraInfo.sizeValidation) {
            // Position got out of sync, reset.
            positionsExtraInfo.delete(i.symbol);
          } else if (positionsExtraInfo && position.side === i.side) {
            // Position size incremented.
            const newSize = position.size + i.exec_qty;
            const newEntryPrice =
              (positionExtraInfo.sizeValidation + i.exec_qty) /
              (i.exec_qty / parseFloat(i.price) +
                positionExtraInfo.sizeValidation /
                  positionExtraInfo.entryPriceValidation);
            const breakEvenPrice =
              newSize /
              (newSize / newEntryPrice +
                positionExtraInfo.realizedPnL *
                  (position.side === "Sell" ? -1 : 1));
            positionsExtraInfo.set(i.symbol, {
              ...positionExtraInfo,
              sizeValidation: newSize,
              entryPriceValidation: newEntryPrice,
              breakEvenPrice,
              executions,
            });
          } else {
            // Position size reduced.
            const newSize = position.size - i.exec_qty;
            const executionPnL = round(
              i.exec_qty *
                (i.side === "Sell"
                  ? 1 / positionExtraInfo.entryPriceValidation -
                    1 / parseFloat(i.price)
                  : 1 / parseFloat(i.price) -
                    1 / positionExtraInfo.entryPriceValidation) -
                parseFloat(i.exec_fee),
              8,
            );
            const realizedPnL = positionExtraInfo.realizedPnL + executionPnL;
            const breakEvenPrice =
              newSize /
              (newSize / positionExtraInfo.entryPriceValidation +
                realizedPnL * (position.side === "Sell" ? -1 : 1));
            if (newSize > 0) {
              positionsExtraInfo.set(i.symbol, {
                ...positionExtraInfo,
                sizeValidation: newSize,
                realizedPnL,
                breakEvenPrice,
                executions,
              });
            } else {
              positionsExtraInfo.delete(i.symbol);
            }
          }
        }
      }
    });

    return {
      ...state,
      account: {
        ...account,
        positionsExtraInfo,
      },
    };
  } else if (action.type === "BYBIT_WALLETS_UPDATE") {
    let {account} = state;
    if (!account) {
      return state;
    }
    const wallets = new Map(state.account?.wallets);
    action.wallets.forEach((i) => wallets.set(i.coin, i));
    return {
      ...state,
      account: {
        ...account,
        wallets,
      },
    };
  }
  return state;
}
