/**
 * @flow
 */

import TimerMixin from "react-timer-mixin";
import {eventChannel} from "redux-saga";
import CryptoJS from "crypto-js";

const WEBSOCKET_CHECK_MILLISECONDS = 30000;

export type Symbol = "BTCUSD";
export type Coin = "BTC";

export const OrderActiveStatusSet: Set<
  $PropertyType<Order, "order_status">,
> = new Set(["Created", "New", "PartiallyFilled", "PendingCancel"]);

export type OutboundMessage =
  | {op: "ping"}
  | {op: "unsubscribe", args: Array<string>}
  | {op: "subscribe", args: Array<string>};

export type BookEntry = {
  price: string,
  symbol: Symbol,
  id: number,
  side: "Buy" | "Sell",
  size: number,
};

export type Wallet = {
  available_balance: string,
  coin: string,
  wallet_balance: string,

  // OJO: no esta en /v2/private/order/list asi que lo dejamos desactivado
  // para no usarlo por error.
  // user_id: number,
};

export type Position = {
  user_id: number,
  symbol: Symbol,
  side: "None" | "Buy" | "Sell",
  wallet_balance: string,
  cum_realised_pnl: string,
  realised_pnl: string,
  entry_price: string,
  leverage: string,
  liq_price: string,
  size: number,
  occ_closing_fee: string,
  occ_funding_fee: string,
  position_value: string,
  position_margin: string,
  order_margin: string,
  bust_price: string,
  stop_loss: string,
  // created_at?: string, // Este solo viene via API no via WS.

  // Isolated: false
  // auto_add_margin: 1
  // available_balance: "0.00500827"
  // mode: 0
  // order_margin: "0.00001918"
  // position_idx: 0
  // position_seq: 0
  // position_status: "Normal"
  // risk_id: 1
  // size: 0
  // take_profit: "0"
  // trailing_active: "0"
  // trailing_stop: "0"
};

export type OrderId = string;
export type OrderLinkId = string;

export type OrderExecution = {|
  exec_fee: string,
  exec_id: string,
  exec_qty: number,
  exec_type: "Trade" | "AdlTrade" | "Funding" | "BustTrade",
  leaves_qty: number,
  order_id: OrderId,
  order_link_id: string,
  order_qty: number,
  side: "Buy" | "Sell",
  symbol: Symbol,
  // falta en api de ordenes pero se lo reasignamos en api de otros valores.
  price: string,
  trade_time: number,
  // faltan en api de ordenes
  // is_maker: boolean,

|};

export type Order = {|
  order_id: OrderId,
  side: "Buy" | "Sell",
  price: string,
  qty: number,
  leaves_qty: number,
  symbol: Symbol,
  cum_exec_fee: string,
  cum_exec_qty: number,
  cum_exec_value: string,
  timestamp: string, // este no esta en el api de ordenes, se lo reasignamos de updated_at
  order_link_id: OrderLinkId,

  order_status:
    | "Created" // - order accepted by the system but not yet put through matching engine
    | "Rejected"
    | "New" // - order has placed successfully
    | "PartiallyFilled"
    | "Filled"
    | "Cancelled"
    | "PendingCancel", // - the matching engine has received the cancellation but there is no guarantee that it will be successful

  order_type: "Limit" | "Market",

  time_in_force:
    | "GoodTillCancel"
    | "ImmediateOrCancel"
    | "FillOrKill"
    | "PostOnly",

  // OJO: no esta en /v2/private/order/list asi que lo dejamos desactivado
  // para no usarlo por error.
  // reduce_only: boolean,
  // close_on_trigger: boolean,
  // create_type:
  //   | "CreateByUser"
  //   | "CreateByClosing"
  //   | "CreateByAdminClosing"
  //   | "CreateByStopOrder"
  //   | "CreateByTakeProfit"
  //   | "CreateByStopLoss"
  //   | "CreateByTrailingStop"
  //   | "CreateByLiq" // Created by partial liquidation
  //   | "CreateByAdl_PassThrough" // Created by ADL
  //   | "CreateByTakeOver_PassThrough", // Created by liquidation takeover
  // cancel_type:
  //   | ""
  //   | "CancelByUser"
  //   | "CancelByReduceOnly"
  //   | "CancelByPrepareLiq" // CancelAllBeforeLiq - Cancelled by force liquidation
  //   | "CancelByPrepareAdl" // CancelAllBeforeAdl - Cancelled by ADL
  //   | "CancelByAdmin"
  //   | "CancelByTpSlTsClear" // This is a cancelled TP/SL/TS order
  //   | "CancelByPzSideCh", // This order is cancelled after TP/SL/TS

  // last_exec_price: "0"
  // stop_loss: "0"
  // take_profit: "0"
  // trailing_stop: "0"
|};

export type StopOrder = {|
  order_id: OrderId,
  order_link_id: string, //	false	string	Customised order ID, maximum length at 36 characters, and order ID under the same agency has to be unique.
  user_id: number,
  symbol: Symbol,
  order_type: "Limit" | "Market",
  side: "Buy" | "Sell",
  qty: string, //	true	string	Order quantity in USD
  price: string, //	false	string	Execution price for conditional order. Required if you make limit price order
  time_in_force:
    | "GoodTillCancel"
    | "ImmediateOrCancel"
    | "FillOrKill"
    | "PostOnly",
  cancel_type: string, //	Trigger scenario for cancel operation
  order_status:
    | "Active" // - order is triggered and placed successfully
    | "Untriggered" // - order waits to be triggered
    | "Triggered" // - order is triggered
    | "Cancelled" // - order is cancelled
    | "Rejected" // - order is triggered but failed to be placed (for example, due to insufficient margin)
    | "Deactivated", // - order was cancelled by user before triggering
  trigger_by: "LastPrice" | "IndexPrice" | "MarkPrice",
  timestamp: string, //	UTC time

  // OJO: esta no esta en/v2/private/stop-order/list pero renombramos el campo.
  trigger_price: string, //	If stop_order_type is TrailingProfit, this field is the trailing stop active price.

  // OJO: no esta en /v2/private/stop-order/list asi que lo dejamos desactivado
  // para no usarlo por error.
  // stop_order_type: string, //	Conditional order type
  // close_on_trigger: boolean, //	What is a close on trigger order? For a closing order. It can only reduce your position, not increase it. If the account has insufficient available balance when the closing order is triggered, then other active orders of similar contracts will be cancelled or reduced. It can be used to ensure your stop loss reduces your position regardless of current available margin.
  // create_type: string, //	Trigger scenario for single action
|};

/*
base_price: "62137.0000"
cancel_type: "UNKNOWN"
created_at: "2021-04-17T06:21:10.766610261Z"
cum_exec_fee: null
cum_exec_qty: 0
cum_exec_value: null
ext_fields: {o_req_num: 156617953}
leaves_qty: 700
leaves_value: "0"
order_id: "b6096a4b-67d5-4fef-9883-8dda3ad668a1"
order_link_id: ""
order_status: "Untriggered"
order_type: "Market"
position_idx: 0
price: "0"
qty: 700
reject_reason: "EC_NoError"
side: "Sell"
symbol: "BTCUSD"
time_in_force: "ImmediateOrCancel"
timestamp: "2021-04-17T06:24:52.830277533Z"
trigger_by: "LastPrice"
trigger_price: "61577.0000"
user_id: 2610226*/

export type BarUpdate = {|
  start: number, //start time of the candle
  end: number, //end time of the candle
  open: number, //open price
  close: number, //close price
  high: number, //max price
  low: number, //min price
  volume: number, //volume
  turnover: number, //turnover
  confirm: boolean, //snapshot flag
  cross_seq: number,
  timestamp: number, //cross time
|};

export type InstrumentInfo = {|
  symbol: string, // Symbol
  last_price_e4: number, // Latest transaction price 10^4
  last_tick_direction:  // Tick Direction
    | "PlusTick" // - Rise in price
    | "ZeroPlusTick" // - Rise in price compared to last trade of different price
    | "MinusTick" // - Drop in price
    | "ZeroMinusTick", // - Drop in price compared to last trade of different price
  prev_price_24h_e4: number, // Price of 24 hours ago * 10^4
  price_24h_pcnt_e6: number, // Percentage change of market price relative to 24h * 10^4
  high_price_24h_e4: number, // The highest price in the last 24 hours * 10^4
  low_price_24h_e4: number, // Lowest price in the last 24 hours * 10^4
  prev_price_1h_e4: number, // Hourly market price an hour ago * 10^4
  price_1h_pcnt_e6: number, // Percentage change of market price relative to 1 hour ago * 10^6
  mark_price_e4: number, // Mark price * 10^4
  index_price_e4: number, // Index_price * 10^4
  open_interest: number, // Open interest. The update is not immediate - slowest update is 1 minute
  open_value_e8: number, // Open position value * 10^8. The update is not immediate - slowest update is 1 minute
  total_turnover_e8: number, // Total turnover * 10^8
  turnover_24h_e8: number, // Turnover for 24H * 10^8
  total_volume: number, // Total volume
  volume_24h: number, // Trading volume in the last 24 hours
  funding_rate_e6: number,
  predicted_funding_rate_e6: number, // Predicted funding rate *10^6
  cross_seq: number, // Cross sequence (internal value)
  created_at: string, // Creation time
  updated_at: string, // Update time
  next_funding_time: string, // Next settlement time of capital cost
  countdown_hour: number, // Countdown of settlement capital cost
|};

export type InstrumentInfoDeltaMessage = {|
  topic: "instrument_info.100ms.BTCUSD",
  type: "delta",
  data: {|
    delete: Array<InstrumentInfo>,
    update: Array<InstrumentInfo>,
    insert: Array<InstrumentInfo>,
    transactTimeE6: number,
  |},
  cross_seq: number,
  timestamp_e6: number,
|};

export type InstrumentInfoSnapshotMessage = {|
  topic: "instrument_info.100ms.BTCUSD",
  type: "snapshot",
  data: InstrumentInfo,
  cross_seq: number,
  timestamp_e6: number,
|};

export type KlineMessage = {|
  topic: "klineV2.1.BTCUSD" | "klineV2.60.BTCUSD" | "klineV2.1D.BTCUSD",
  data: Array<BarUpdate>,
  timestamp_e6: number, //server time
|};

export type InboundMessage =
  | {|request: {|op: string|}, ret_msg: string, success: boolean|}
  | {|
      topic: "order",
      data: Array<Order>,
    |}
  | {|
      topic: "stop_order",
      data: Array<StopOrder>,
    |}
  | {|
      topic: "position",
      data: Array<Position>,
    |}
  | {|
      topic: "wallet",
      data: Array<Wallet>,
    |}
  | {|
      topic: "execution",
      data: Array<OrderExecution>,
    |}
  | {|
      topic: "orderBookL2_25.BTCUSD" | "orderBook_200.100ms.BTCUSD",
      type: "delta",
      data: {|
        delete: Array<BookEntry>,
        update: Array<BookEntry>,
        insert: Array<BookEntry>,
        transactTimeE6: number,
      |},
      cross_seq: number,
      timestamp_e6: number,
    |}
  | {|
      topic: "orderBookL2_25.BTCUSD" | "orderBook_200.100ms.BTCUSD",
      type: "snapshot",
      data: Array<BookEntry>,
      cross_seq: number,
      timestamp_e6: number,
    |}
  | InstrumentInfoSnapshotMessage
  | InstrumentInfoDeltaMessage
  | KlineMessage;

export type WSEvent =
  | {|type: "inbound_message", payload: InboundMessage|}
  | {|type: "connection_opened"|}
  | {|type: "connection_closed", code: number, reason: string|}
  | {|type: "connection_error", error: Object|};

export type Props = {
  apiKey: string,
  apiSecret: string,
  endpoint: string,
};

export default class ByBitWebSocket {
  ws: WebSocket;
  props: Props;
  onEvent: (WSEvent) => void;

  constructor(props: Props) {
    this.props = props;
  }

  stop() {
    this.closeWebSocket("websocket closed for unmount");
    TimerMixin.componentWillUnmount.bind(this)();
  }

  connectWebSocket() {
    if (!this.ws) {
      // Arrancamos este timer una sola vez.
      TimerMixin.setInterval.bind(this)(() => {
        if (this.ws.readyState !== WebSocket.OPEN) {
          console.log("websocket check readyState=", this.ws.readyState);
        }
        if (this.ws.readyState === WebSocket.OPEN) {
          // $FlowFixMe.
          this.ws.sendjson({op: "ping"});
          // this.ws.ping(); // A ByBit no le gusta el ping nativo.
        } else if (this.ws.readyState !== WebSocket.CONNECTING) {
          this.connectWebSocket();
        }
      }, WEBSOCKET_CHECK_MILLISECONDS);
    } else if (this.ws.readyState !== WebSocket.CLOSED) {
      console.log("websocket closing old connection before opening a new one");
      this.ws.close();
    }

    /*
      WebSocket possible readyState
      CONNECTING	0	The connection is not yet open.
      OPEN	1	The connection is open and ready to communicate.
      CLOSING	2	The connection is in the process of closing.
      CLOSED	3	The connection is closed or couldn't be opened.
    */

    // A UNIX timestamp after which the request become invalid. This is to prevent replay attacks.
    // unit:millisecond
    let expires: string = (new Date().getTime() + 10000).toFixed(0);
    let signature = CryptoJS.enc.Hex.stringify(
      CryptoJS.HmacSHA256("GET/realtime" + expires, this.props.apiSecret),
    );
    let url = `${this.props.endpoint}?api_key=${this.props.apiKey}&expires=${expires}&signature=${signature}`;

    this.ws = new WebSocket(url);
    this.ws.onopen = () => {
      this.onWebSocketOpen();
    };
    this.ws.onclose = (e: Event) => {
      this.onWebSocketClose(e);
    };
    this.ws.onerror = (e: Event) => {
      this.onWebSocketError(e);
    };
    this.ws.onmessage = (e: MessageEvent) => {
      if (typeof e.data === "string") {
        let dataString: string = e.data;
        this.onEvent &&
          this.onEvent({
            type: "inbound_message",
            payload: JSON.parse(dataString),
          });
      }
    };
    // $FlowFixMe.
    this.ws.sendjson = (data) => {
      this.ws.send(JSON.stringify(data));
    };
  }

  closeWebSocket(msg: string) {
    console.log("closing websocket:", msg);
    if (this.ws) {
      this.ws.close();
    }
  }

  onWebSocketOpen() {
    this.onEvent
      ? this.onEvent({type: "connection_opened"})
      : console.log("websocket opened", this.onEvent);
  }

  onWebSocketError(e: Object) {
    this.onEvent
      ? this.onEvent({type: "connection_error", error: e})
      : console.log("websocket error", e.message);
    this.closeWebSocket("onWebSocketError");
  }

  onWebSocketClose(e: Object) {
    this.onEvent
      ? this.onEvent({
          type: "connection_closed",
          code: e.code,
          reason: e.reason,
        })
      : console.log("websocket closed", e.code, e.reason);
    // Aca no reintentamos conectar porque esto tambien se
    // ejecuta cuando desactivamos el seguimiento.
  }

  sendMessage(msg: OutboundMessage): void {
    // $FlowFixMe.
    this.ws.sendjson(msg);
  }

  createEventChannel: * = () => {
    const self = this;
    return eventChannel((emit) => {
      self.onEvent = function(event: WSEvent) {
        emit(event);
      };
      self.connectWebSocket();
      return () => {
        self.stop();
      };
    });
  };
}
