/**
 * @flow
 */

import * as IOEffects from "redux-saga/effects";
import {eventChannel} from "redux-saga";
// import {REHYDRATE} from 'redux-persist/constants';
import type {Action, Store} from "../actions/types";
// import * as utils from "pulpo/rn/utils";

export function* select<R>(func: (Store) => R): Generator<*, R, *> {
  return yield IOEffects.select(func);
}

export function* put(action: Action): Generator<void, void, void> {
  return yield IOEffects.put(action);
}

// Esta version no la usamos porque si le pasamos parametros demas
// no nos avisa. La idea es usar la siguiente que no permite parametros
// cosa de siempre pasar un closure.
// export function * call<A, R>(func: (...args: A) => Promise<R>, ...args: A): Generator<*, R, *> {
//   return yield IOEffects.call(func, ...args);
// }

export function* call<A, R>(func: () => Promise<R> | void): Generator<*, R, *> {
  return yield IOEffects.call(func);
}

// export function* waitUntilRehydrated(): Generator<*, void, *> {
//   let isLoggedIn = yield* select(store => store.user.isLoggedIn);
//   if (!isLoggedIn) {
//     yield IOEffects.take(REHYDRATE);
//   }
// }

// utils.configureNotificationChannel({
//   id: "MyApp",
//   name: "Myapp",
//   description: "Notifications from MyApp",
//   enableVibration: false,
//   importance: 4, // IMPORTANCE_HIGH (android >= 8)
// });
// const timerFactory = utils.createTimerFactory();

// export function createTimerChannel(
//   frequency: number,
//   notificationConfig?: utils.AndroidNotification,
// ): * {
//   if (!frequency) {
//     throw new Error("Cannot create createTimerChannel");
//   }
//   return eventChannel((emit) => {
//     const timerStop = timerFactory.setInterval(
//       () => emit(true),
//       frequency,
//       notificationConfig,
//     );
//     return timerStop;
//   });
// }

// export const backgroundDelay = (
//   delay: number,
//   notificationConfig?: utils.AndroidNotification,
// ): Promise<void> =>
//   new Promise((resolve, reject) => {
//     timerFactory.setTimeout(resolve, delay, notificationConfig);
//   });

const myExports = {
  select,
  call,
  put,
  // createTimerChannel,
  // backgroundDelay,
  // waitUntilRehydrated,

  // Original funcs.
  orig: IOEffects,
};
export default myExports;