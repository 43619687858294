/**
 * @flow
 */
"use strict";

import * as React from "react";
import actions from "../actions";
import * as api2 from "../api2";
import * as types from "../actions/types";
import * as tvutils from "../lib/TradingViewUtils";
import {widget} from "../lib/charting_library";
import OrderLine from "../containers/TradingViewChartOrderLineContainer";
import StopOrderLine from "../containers/TradingViewChartStopOrderLineContainer";
import PositionLine from "./TradingViewChartPositionLine";
import BreakEvenLine from "./TradingViewChartBreakEvenLine";
import AlarmLine from "./TradingViewChartAlarmLine";
import Shape from "./TradingViewChartShape";

type DefaultProps = {|
  containerId: string,
  libraryPath: string,
  fullscreen: boolean,
  autosize: boolean,
  supportedResolutions: Array<tvutils.Resolution>,
|};

export type Props = {|
  ...DefaultProps,
  settings: types.TradingSettings,
  account: types.ByBitAccountState,
  orders: Array<types.bybitws.Order>,
  stopOrders: Array<types.bybitws.StopOrder>,
  book: types.OrderBook,
  position: ?types.bybitws.Position,
  positionExtraInfo: ?types.ByBitPositionExtraInfo,
  placeLimitPostOnlyOrder: typeof actions.bybit.placeLimitPostOnlyOrder,
  subscribeBarUpdates: typeof actions.bybit.subscribeBarUpdates,
  unsubscribeBarUpdates: typeof actions.bybit.unsubscribeBarUpdates,
  updateTradingSettings: typeof actions.user.updateTradingSettings,
|};

export default class MyComponent extends React.PureComponent<Props> {
  static defaultProps: DefaultProps = {
    containerId: "trading-view-chart",
    libraryPath: "charting_library/",
    fullscreen: false,
    autosize: true,
    supportedResolutions: [
      "1",
      "3",
      "5",
      "15",
      "30",
      "60",
      "120",
      "240",
      "360",
      "720",
      "1D",
      // "1W",
      // "1M",
    ],
  };

  tvWidget: any = null;

  componentDidMount() {
    const datafeed = new tvutils.Datafeed({
      subscribeBarUpdates: this.props.subscribeBarUpdates,
      unsubscribeBarUpdates: this.props.unsubscribeBarUpdates,
      symbols: new Map([
        [
          ".BTCUSD",
          {
            ticker: ".BTCUSD",
            name: ".BTCUSD",
            full_name: ".BTCUSD",
            description: ".BTCUSD",
            type: "crypto",
            session: "24x7",
            timezone: "Etc/UTC",
            exchange: "BB",
            minmov: 5,
            minmov2: 0,
            pricescale: 10,
            fractional: false,
            has_intraday: true,
            has_no_volume: true,
            has_weekly_and_monthly: false,
            supported_resolutions: this.props.supportedResolutions,
            volume_precision: 2,
            data_status: "streaming",
            format: "price",
          },
        ],
        [
          "BTCUSD",
          {
            ticker: "BTCUSD",
            name: "BTCUSD",
            full_name: "BTCUSD",
            description: "BTCUSD",
            type: "crypto",
            session: "24x7",
            timezone: "Etc/UTC",
            exchange: "BB",
            minmov: 5,
            minmov2: 0,
            pricescale: 10,
            fractional: false,
            has_intraday: true,
            has_no_volume: true,
            has_weekly_and_monthly: false,
            supported_resolutions: this.props.supportedResolutions,
            volume_precision: 2,
            data_status: "streaming",
            format: "price",
          },
        ],
      ]),
      configurationData: {
        exchanges: [
          {
            name: "BB",
            value: "BB",
            desc: "BB",
          },
        ],
        symbols_types: [{name: "Crypto", value: "Crypto"}],
        supported_resolutions: this.props.supportedResolutions,
        supports_marks: false,
        supports_time: true,
        supports_timescale_marks: false,
      },
    });
    const widgetOptions = {
      symbol: this.props.account.symbol,
      interval: tvutils.loadInterval(this.props.containerId),
      datafeed,
      container_id: this.props.containerId,
      library_path: this.props.libraryPath,
      locale: "en",
      fullscreen: this.props.fullscreen,
      autosize: this.props.autosize,
      theme: "Dark",
      auto_save_delay: 5,
      disabled_features: [
        "header_symbol_search",
        "symbol_search_hot_key",
        "show_interval_dialog_on_key_press",
        "header_compare",
        "border_around_the_chart",
        "header_saveload",
        "remove_library_container_border",
        "go_to_date",
        "volume_force_overlay",
        "control_bar",
        // "legend_widget",
      ],
      enabled_features: [
        // Esto habilita el boton + en el crosshair.
        "chart_crosshair_menu",
      ],
      saved_data: tvutils.loadSetting(this.props.containerId),
      // settings_adapter: {
      //     initialSettings: {
      //         "trading.orderPanelSettingsBroker": JSON.stringify({
      //             showRelativePriceControl: !1,
      //             showCurrencyRiskInQty: !1,
      //             showPercentRiskInQty: !1,
      //             showBracketsInCurrency: !1,
      //             showBracketsInPercent: !1
      //         }),
      //         "trading.chart.proterty": localStorage.getItem("trading.chart.proterty") || JSON.stringify({
      //             hideFloatingPanel: 1
      //         }),
      //         "chart.favoriteDrawings": localStorage.getItem("chart.favoriteDrawings") || JSON.stringify([]),
      //         "chart.favoriteDrawingsPosition": localStorage.getItem("chart.favoriteDrawingsPosition") || JSON.stringify({})
      //     },
      //     setValue: (e,t)=>{
      //         localStorage.setItem(e, t)
      //     }
      //     ,
      //     removeValue: e=>{
      //         localStorage.removeItem(e)
      //     }
      // },
      //   settings_adapter: {
      //     // initialSettings: {},
      //     setValue: (e, t) => {
      //       localStorage.setItem(
      //         "tv_chart_settings_" + this.props.containerId + "_" + e,
      //         t,
      //       );
      //     },
      //     removeValue: (e) => {
      //       localStorage.removeItem(
      //         "tv_chart_settings_" + this.props.containerId + "_" + e,
      //       );
      //     },
      //   },
    };

    const tvWidget = new widget(widgetOptions);

    tvWidget.onChartReady(() => {
      // let pepe = tvWidget.activeChart();
      // pepe
      //   .selection()
      //   .onChanged()
      //   .subscribe(null, (s) => console.log(pepe.selection().allSources()));
      tvWidget.chart().executeActionById("drawingToolbarAction");
      tvWidget.subscribe("onAutoSaveNeeded", () => {
        tvutils.saveSetting(this.props.containerId, tvWidget);
      });
      tvWidget
        .chart()
        .onIntervalChanged()
        .subscribe(null, (intervalCur) => {
          tvutils.saveInterval(this.props.containerId, intervalCur);
          tvutils.saveSetting(this.props.containerId, tvWidget);
        });
      tvWidget.subscribe("onPlusClick", (params) => {
        const symbolInfo = datafeed.props.symbols.get(params.symbol);
        if (symbolInfo) {
          const fraction = symbolInfo.minmov / symbolInfo.pricescale;
          this.props.placeLimitPostOnlyOrder({
            side:
              params.price <= this.props.book.bestBuyAmount ? "Buy" : "Sell",
            symbol: params.symbol,
            qty: parseInt(this.props.settings.orderSize),
            price: Math.round(params.price / fraction) * fraction,
          });
        }
      });
      tvWidget.onContextMenu((unixtime: number, price: number) => [
        {
          position: "top",
          text: "Add Stop Order",
          click: () => {
            this.props.updateTradingSettings({
              addStopOrderFormDialogBasePrice: this.props.book.bestBuyAmount,
              addStopOrderFormDialogPrice: Math.round(price).toFixed(0),
            });
          },
        },
        {
          position: "top",
          text: "Add Price Alert",
          click: () => {
            this.props.updateTradingSettings({
              alarms: [...this.props.settings.alarms, price],
            });
          },
        },
      ]);
      tvutils.createMarkPriceStudy(tvWidget, "." + widgetOptions.symbol);
      this.tvWidget = tvWidget;
      this.forceUpdate();
    });
  }

  componentWillUnmount() {
    if (this.tvWidget !== null) {
      this.tvWidget.remove();
      this.tvWidget = null;
    }
  }

  render(): React$Node {
    return (
      <>
        <div
          id={this.props.containerId}
          style={{
            height: "100%",
          }}
        />
        {this.tvWidget ? (
          <>
            {this.props.position && this.props.position.side !== "None" ? (
              <PositionLine
                key={this.props.position.side}
                tvWidget={this.tvWidget}
                position={this.props.position}
              />
            ) : null}
            {this.props.settings.alarms.map((i) => (
              <AlarmLine
                key={i}
                alarm={i}
                account={this.props.account}
                settings={this.props.settings}
                tvWidget={this.tvWidget}
                updateTradingSettings={this.props.updateTradingSettings}
              />
            ))}
            {this.props.position &&
            this.props.position.side !== "None" &&
            this.props.positionExtraInfo &&
            this.props.positionExtraInfo.sizeValidation ===
              this.props.position.size &&
            this.props.positionExtraInfo.breakEvenPrice > 0 &&
            this.props.positionExtraInfo.breakEvenPrice !==
              this.props.positionExtraInfo.entryPriceValidation ? (
              <BreakEvenLine
                key={"break-even-" + this.props.position.side}
                tvWidget={this.tvWidget}
                position={this.props.position}
                positionExtraInfo={this.props.positionExtraInfo}
              />
            ) : null}
            {(
              (this.props.settings.executionIndicators &&
                this.props.position &&
                this.props.position.side !== "None" &&
                this.props.positionExtraInfo?.executions) ||
              []
            ).map((i) => (
              <Shape
                key={i.exec_id}
                time={new Date(i.trade_time).getTime() / 1000}
                price={parseFloat(i.price)}
                shape={i.side === "Buy" ? "arrow_up" : "arrow_down"}
                tvWidget={this.tvWidget}
                // text={`${i.exec_qty}@${i.price}`}
              />
            ))}
            {this.props.orders.map((i) => (
              <OrderLine
                key={i.order_id}
                tvWidget={this.tvWidget}
                order={i}
                position={this.props.position}
              />
            ))}
            {this.props.stopOrders.map((i) => (
              <StopOrderLine
                key={i.order_id}
                tvWidget={this.tvWidget}
                order={i}
              />
            ))}
          </>
        ) : null}
      </>
    );
  }
}
