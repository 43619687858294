/**
 * @flow
 */
"use strict";

import React, {Component, PropTypes} from "react";
import actions from "../actions";
import * as types from "../actions/types";
import * as api2 from "../api2";
import {ThemeContext} from "styled-components";
import {Panel, OrderButton, OrderSizeButton} from "./StyledComponents";

export type Props = {|
  cancelOrder: typeof actions.bybit.cancelOrder,
  cancelAllOrders: typeof actions.bybit.cancelAllOrders,
  account: types.ByBitAccountState,
  settings: types.TradingSettings,
|};

type State = {};

export default class MyComponent extends React.Component<Props, State> {
  // static defaultProps = {}

  constructor(props: Props) {
    super(props);
    // this.state = {};
  }

  getActiveOrders(): Array<types.bybitws.Order> {
    return Array.from(this.props.account.orders.values()).filter((i) =>
      types.bybitws.OrderActiveStatusSet.has(i.order_status),
    );
  }

  cancelBuyOrders: () => void = () => {
    this.getActiveOrders()
      .filter(
        (i) =>
          i.side === "Buy" &&
          i.order_link_id.endsWith("_" + this.props.settings.orderGroup),
      )
      .forEach((i) =>
        this.props.cancelOrder({symbol: i.symbol, order_id: i.order_id}),
      );
  };

  cancelSellOrders: () => void = () => {
    this.getActiveOrders()
      .filter(
        (i) =>
          i.side === "Sell" &&
          i.order_link_id.endsWith("_" + this.props.settings.orderGroup),
      )
      .forEach((i) =>
        this.props.cancelOrder({symbol: i.symbol, order_id: i.order_id}),
      );
  };

  cancelAllOrders: () => void = () => {
    this.props.cancelAllOrders({symbol: this.props.account.symbol});
  };

  render(): React$Node {
    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <Panel>
            <div style={{...styles.rFlex}}>
              <div style={{flex: 1}}>
                <button onClick={this.cancelBuyOrders}>
                  Cancel Buy Orders
                </button>
              </div>
              <div style={{flex: 1, textAlign: "center"}}>
                <button onClick={this.cancelAllOrders}>Cancel All</button>
              </div>
              <div style={{flex: 1, textAlign: "right"}}>
                <button onClick={this.cancelSellOrders}>
                  Cancel Sell Orders
                </button>
              </div>
            </div>
          </Panel>
        )}
      </ThemeContext.Consumer>
    );
  }
}

const styles = {
  cFlex: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  cFlexAuto: {
    display: "flex",
    flexDirection: "column",
  },
  rFlex: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
  },
  rFlexAuto: {
    display: "flex",
    flexDirection: "row",
  },
  labelText: {
    flex: 1,
  },
  itemText: {
    flex: 2,
    textAlign: "right",
  },
  headerText: {
    fontSize: "1.2rem",
    marginBottom: "0.5rem",
  },
  orderButton: {
    padding: "3rem",
    fontSize: "2rem",
  },
};
