/**
 * @flow
 */
'use strict';

import React, {Component, PropTypes} from 'react';
import actions from '../actions';
import * as types from '../actions/types';
import * as api2 from '../api2';


export type Props = {
  settings: types.TradingSettings,
  keyName: $Keys<types.TradingSettings>,
  save: boolean,
  adapter: (string) => string,
  style: *,
  updateTradingSettings: typeof actions.user.updateTradingSettings,
  autoFocus?: boolean,
}


type State = {
}


export default class MyComponent extends React.Component<Props, State> {
  // static defaultProps = {}

  constructor(props: Props) {
    super(props);
    // this.state = {};
  }

  render(): React$Node {
    return (
      <input
        type={"text"}
        autoComplete={"off"}
        value={this.props.settings[this.props.keyName] || ""}
        style={this.props.style}
        autoFocus={!!this.props.autoFocus}
        onChange={(e) => {
          let txt = this.props.adapter(e.target.value);
          this.props.updateTradingSettings({[`${this.props.keyName}`]: txt});
        }}
      />
    );
  }

}


const styles = {
  container: {
    flex: 1,
  },
};
