/**
 * @flow
 */

import type {Action, ThunkAction, Store} from "../actions/types";
import actions from "../actions";
import * as types from "../actions/types";
import * as api2 from "../api2";
import {v4 as uuid4} from "uuid";

export function connect(params: {|
  apiKey: string,
  apiSecret: string,
  symbol: types.bybitws.Symbol,
|}): Action {
  return {type: "BYBIT_START_CONNECTION_REQUEST", ...params};
}

export function cancelOrder(
  request: types.bybitapi.CancelOrderRequest,
): Action {
  return {type: "BYBIT_CANCEL_ORDER_REQUEST", request};
}

export function cancelAllOrders(
  request: types.bybitapi.CancelAllOrdersRequest,
): Action {
  return {type: "BYBIT_CANCEL_ALL_ORDERS_REQUEST", request};
}

export function replaceOrder(
  request: types.bybitapi.ReplaceOrderRequest,
  order: types.bybitws.Order,
): Action {
  return {type: "BYBIT_REPLACE_ORDER_REQUEST", request, order};
}

export function placeOrder(request: types.bybitapi.PlaceOrderRequest): Action {
  return {type: "BYBIT_PLACE_ORDER_REQUEST", request};
}

export function placeStopOrder(request: types.bybitapi.PlaceStopOrderRequest): Action {
  return {type: "BYBIT_PLACE_STOP_ORDER_REQUEST", request};
}

export function placeLimitPostOnlyOrder(
  params: $Shape<types.bybitapi.PlaceOrderRequest>,
): ThunkAction {
  return (dispatch, getState) => {
    const store = getState();
    dispatch(
      placeOrder({
        ...params,
        order_link_id: uuid4() + "_" + store.user.tradingSettings.orderGroup,
        order_type: "Limit",
        time_in_force: "PostOnly",
        reduce_only: false,
      }),
    );
  };
}


export function placeMarketOrder(
  params: $Shape<types.bybitapi.PlaceOrderRequest>,
): ThunkAction {
  return (dispatch, getState) => {
    const store = getState();
    dispatch(
      placeOrder({
        ...params,
        order_link_id: uuid4() + "_" + store.user.tradingSettings.orderGroup,
        order_type: "Market",
        time_in_force: "FillOrKill",
        reduce_only: false,
      }),
    );
  };
}


export function placeStopMarketOrder(
  params: $Shape<types.bybitapi.PlaceStopOrderRequest>,
): ThunkAction {
  return (dispatch, getState) => {
    const store = getState();
    dispatch(
      placeStopOrder({
        ...params,
        order_link_id: uuid4() + "_" + store.user.tradingSettings.orderGroup,
        order_type: "Market",
        time_in_force: "GoodTillCancel",
      }),
    );
  };
}


export function setTradingStop(
  request: types.bybitapi.SetTradingStopRequest,
): Action {
  return {type: "BYBIT_SET_TRADING_STOP_REQUEST", request};
}

export function subscribeBarUpdates(params: {|
  id: string,
  symbol: string,
  resolution: types.tvutils.Resolution,
  callback: (types.tvutils.Bar) => void,
|}): Action {
  return {
    type: "BYBIT_SUBSCRIBE_BAR_UPDATES",
    ...params,
  };
}

export function unsubscribeBarUpdates(params: {|id: string|}): Action {
  return {
    type: "BYBIT_UNSUBSCRIBE_BAR_UPDATES",
    ...params,
  };
}

export function cancelStopOrder(
  request: types.bybitapi.CancelStopOrderRequest,
): Action {
  return {type: "BYBIT_CANCEL_STOP_ORDER_REQUEST", request};
}

export function replaceStopOrder(
  request: types.bybitapi.ReplaceStopOrderRequest,
  order: types.bybitws.StopOrder,
): Action {
  return {type: "BYBIT_REPLACE_STOP_ORDER_REQUEST", request, order};
}
