/**
 * @flow
 */

import React from "react";
import logo from "./logo.svg";
import MainScreen from "./containers/MainScreenContainer";
import {ThemeProvider} from "styled-components";
import {GlobalStyles} from "./global";

import lightTheme from "./themes/light";
import darkTheme from "./themes/dark";
import styled from "styled-components";

function App(): React$Node {
  // const getCurrentTheme = (): boolean =>
  //   window.matchMedia("(prefers-color-scheme: dark)").matches;
  // const [isDark, setDark] = React.useState<boolean>(getCurrentTheme());
  const [isDark, setDark] = React.useState<boolean>(true);
  return (
    <ThemeProvider theme={isDark ? darkTheme : lightTheme}>
      <GlobalStyles />
      {/* <button onClick={() => setDark(!isDark)}>pepe</button>
        <span>{isDark ? "dark" : "light"}</span> */}
      <MainScreen />
    </ThemeProvider>
  );
}

export default App;
