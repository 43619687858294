/**
 * @flow
 */


import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import type { Store, MyDispatch, Action } from '../actions/types.js';
import actions from '../actions';
import * as api2 from '../api2';
import MyComponent, {type Props} from '../components/CancelOrderPanel';


type OwnProps = {|
|}

const connector = connect<Props, OwnProps, _, _, _, _>(
  (store: Store, ownProps: OwnProps) => ({
    account: store.bybit.account,
    settings: store.user.tradingSettings,
  }),
  (dispatch: MyDispatch, ownProps: OwnProps) => ({
    cancelOrder: bindActionCreators(actions.bybit.cancelOrder, dispatch),
    cancelAllOrders: bindActionCreators(
      actions.bybit.cancelAllOrders,
      dispatch,
    ),
  }),
);

const Connected: * = connector(MyComponent);
export default Connected;

function test(props: OwnProps) {
  return <Connected {...props} />;
}


