/**
 * @flow
 */
"use strict";

import * as React from "react";
import actions from "../actions";
import * as api2 from "../api2";
import * as types from "../actions/types";
import * as tvutils from "../lib/TradingViewUtils";
import {ThemeContext} from "styled-components";
import * as colorUtils from "../lib/normalizeColor";

const DEFAULT_VOLUME = 1;
function playAudio(path: string, volume: number = DEFAULT_VOLUME) {
  const audio = new Audio(window.location.origin + path);
  audio.volume = volume;
  audio.play();
}

export type Props = {|
  tvWidget: any,
  price: number,
  time: number,
  shape: string,
  text?: string,
|};

export default class MyComponent extends React.PureComponent<Props> {
  static contextType: React$Context<any> = ThemeContext;
  shape: any = null;

  componentDidMount() {
    this.shape = this.props.tvWidget.chart().createShape(
      {
        time: this.props.time,
        price: this.props.price,
      },
      {
        shape: this.props.shape,
        disableSave: true,
        disableUndo: true,
        showInObjectsTree: false,
        lock: true,
        zOrder: "top",
        text: this.props.text,
        // overrides: {color: "#787878"},
      },
    );
  }

  componentWillUnmount() {
    if (this.shape != null) {
      const chart = this.props.tvWidget?.chart();
      if (chart) {
        chart.removeEntity(this.shape);
      }
    }
  }

  render(): React$Node {
    return null;
  }
}
