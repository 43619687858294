/**
 * @flow
 */

import * as types from "../actions/types";
import * as api2 from "../api2";
import actions from "../actions";

export function loginUser(
  apiEndpoint: string,
  user: string,
  password: string,
): types.Action {
  return {
    type: "LOGIN_REQUEST",
    user: user,
    password: password,
    apiEndpoint: apiEndpoint,
  };
}

export function loginError(error: string): types.Action {
  return {
    type: "LOGIN_FAILED",
    error: error,
  };
}

export function loginSuccess(
  apiEndpoint: string,
  user: string,
  token: string,
  assignments: Array<string>,
  locomotoUrl: string,
): types.Action {
  return {
    type: "LOGIN_SUCCESS",
    user: user,
    token: token,
    apiEndpoint,
  };
}

export function logout(): types.ThunkAction {
  return (dispatch, getState) => {
    dispatch({type: "LOGOUT_SUCCESS"});
  };
}

export function updateTradingSettings(settings: $Shape<types.TradingSettings>): types.Action {
  return {type: 'UPDATE_TRADING_SETTINGS', settings}
}