/**
 * @flow
 */

import type {Action} from "../actions/types";
import saga from "./lib";
import * as api from "../api";
import {eventChannel} from "redux-saga";

const DEFAULT_VOLUME = 0.1;
function playAudio(path: string, volume: number = DEFAULT_VOLUME) {
  const audio = new Audio(window.location.origin + path);
  audio.volume = volume;
  audio.play();
}

function* isAudioEnabled() {
  return yield* saga.select((store) => store.user.tradingSettings.audioEnabled);
}

function* onByBitOrdersUpdate(action: Action) {
  if (action.type !== "BYBIT_ORDERS_UPDATE") throw new Error("Invalid Action");
  const {failedOrders, newOrders} = yield* saga.select((store) => store.bybit);
  if (!(yield* isAudioEnabled())) {
    return;
  }
  if (failedOrders) {
    playAudio("/cancelled.mp3", 1);
  }
  if (newOrders) {
    playAudio("/beep_success.mp3");
  }
}
function* onByBitOrdersExecutionUpdate(action: Action) {
  if (action.type !== "BYBIT_ORDERS_EXECUTION_UPDATE")
    throw new Error("Invalid Action");
  if (!(yield* isAudioEnabled())) {
    return;
  }
  playAudio("/beep_order_filled.flac");
}

function* speakOrders() {
  // Esto espera un delay y habla todas las ordenes ejecutadas durante
  // ese tiempo.

  const execChan = yield saga.orig.actionChannel([
    "BYBIT_ORDERS_EXECUTION_UPDATE",
  ]);
  while (true) {
    let buy = 0;
    let sell = 0;

    while (true) {
      const {
        action,
        timeout,
      }: {action: Action, timeout: any} = yield saga.orig.race({
        action: saga.orig.take(execChan),
        timeout: saga.orig.delay(1000),
      });
      if (action && action.type === "BYBIT_ORDERS_EXECUTION_UPDATE") {
        action.executions.forEach((i) => {
          if (i.side == "Buy") {
            buy += i.exec_qty;
          } else {
            sell += i.exec_qty;
          }
        });
      }
      if (timeout && (buy > 0 || sell > 0)) {
        break;
      }
    }

    const position = yield* saga.select((store) =>
      store.bybit.account.positions.get(store.bybit.account.symbol),
    );
    if (!position) {
      console.log("cancelling speakOrder, no position");
      continue;
    }

    const speakChannel = eventChannel((emit) => {
      const msg = new SpeechSynthesisUtterance();
      const txts = [];
      if (buy) {
        txts.push(`compramos ${buy}`);
      }
      if (sell) {
        txts.push(`vendimos ${sell}`);
      }
      txts.push(`posicion ${position.size}`);
      msg.text = txts.join(",");
      msg.lang = "es-AR";
      msg.onend = (event) => {
        emit({done: true}); // no importa que emitimos pero no puede ser nada.
      };
      window.speechSynthesis.speak(msg);
      return () => {
        // nothing to clear.
      };
    });

    yield saga.orig.take(speakChannel);
  }
}

function* mySagas(): Generator<any, any, any> {
  yield saga.orig.all([
    api.forkForever(speakOrders),
    saga.orig.takeEvery("BYBIT_ORDERS_UPDATE", onByBitOrdersUpdate),
    saga.orig.takeEvery(
      "BYBIT_ORDERS_EXECUTION_UPDATE",
      onByBitOrdersExecutionUpdate,
    ),
    saga.orig.takeEvery(
      yield saga.orig.actionChannel([
        "BYBIT_CANCEL_ORDER_REQUEST",
        "BYBIT_CANCEL_ALL_ORDERS_REQUEST",
        "BYBIT_PLACE_ORDER_REQUEST",
        "BYBIT_PLACE_STOP_ORDER_REQUEST",
        "BYBIT_REPLACE_ORDER_REQUEST",
      ]),
      function*() {
        (yield* isAudioEnabled()) && playAudio("/beep_start.mp3");
      },
    ),
    saga.orig.takeEvery(
      yield saga.orig.actionChannel([
        "BYBIT_CANCEL_ORDER_FAILED",
        "BYBIT_CANCEL_ALL_ORDERS_FAILED",
        "BYBIT_PLACE_ORDER_FAILED",
        "BYBIT_PLACE_STOP_ORDER_FAILED",
        "BYBIT_REPLACE_ORDER_FAILED",
      ]),
      function*() {
        playAudio("/beep_fail.mp3");
      },
    ),
    saga.orig.takeEvery(
      yield saga.orig.actionChannel([
        "BYBIT_CANCEL_ORDER_SUCCESS",
        "BYBIT_CANCEL_ALL_ORDERS_SUCCESS",
      ]),
      function*() {
        (yield* isAudioEnabled()) && playAudio("/beep_success.mp3");
      },
    ),
  ]);
}

export default mySagas;
