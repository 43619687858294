/**
 * @flow
 */

import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import type {Store, MyDispatch, Action} from "../actions/types.js";
import actions from "../actions";
import * as api2 from "../api2";
import MyComponent, {type Props} from "../components/PlaceOrderPanel";
import {buildOrderBook, buildOpenOrdersInfo} from "../lib/ByBitUtils";

type OwnProps = {||};

const connector = connect<Props, OwnProps, _, _, _, _>(
  (store: Store, ownProps: OwnProps) => {
    const orderBook = buildOrderBook(store.bybit.account.orderBook, 10);
    return {
      orderBook,
      account: store.bybit.account,
      settings: store.user.tradingSettings,
      openOrdersInfo: buildOpenOrdersInfo(store.bybit.account, orderBook),
    };
  },
  (dispatch: MyDispatch, ownProps: OwnProps) => ({
    placeLimitPostOnlyOrder: bindActionCreators(
      actions.bybit.placeLimitPostOnlyOrder,
      dispatch,
    ),
    placeMarketOrder: bindActionCreators(
      actions.bybit.placeMarketOrder,
      dispatch,
    ),
    replaceOrder: bindActionCreators(
      actions.bybit.replaceOrder,
      dispatch,
    ),
    updateTradingSettings: bindActionCreators(
      actions.user.updateTradingSettings,
      dispatch,
    ),
  }),
);

const Connected: * = connector(MyComponent);
export default Connected;

function test(props: OwnProps) {
  return <Connected {...props} />;
}
