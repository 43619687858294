/**
 */

import * as api2 from "./api2";
import type {Action} from "./actions/types";

export const defaultLoadingError =
  "Hubo un problema cargando la información. Reintenta en unos segundos.";
export const defaultError = "Ups! Ocurrió un error, intenta nuevamente.";

export function safeJSONParse(str: string): any {
  // Esta funcion siempre devuelve un string, aunque falle el parse del JSON.
  try {
    return JSON.parse(str);
  } catch (e) {
    return "";
  }
}

export function getApi(state: Object): api2.DefaultApi {
  let configuration = new api2.Configuration();
  configuration.apiKey = {Authorization: state.user.token};
  return new api2.DefaultApi(undefined, state.user.apiEndpoint, configuration);
}

// Esta funcion es para sagas que pueden reintentarse. No utilizar en funciones
// que alteren de cualquier forma el sistema ya que al reintentarse podriamos
// duplicar el resultado. Esto solo puede utilizarse en funciones de solo
// lectura.
type OnErrorOpts = {
  retries: number,
  delay: number,
  handler: (Error, any) => ?Action,
};
export function onErrorRetry<T: *>(
  func: T,
  {retries, delay, handler}: OnErrorOpts,
): T {
  var origSagaEffects = require("redux-saga/effects");
  var origSaga = require("redux-saga");
  function* wrapper(action: Action): any {
    for (let x = 0; x <= retries; x++) {
      try {
        yield func(action);
        return;
      } catch (e) {
        if (x < retries) {
          let backoff = delay * Math.pow(2, x);
          console.log(func.name, "failed, retrying in", backoff);
          yield origSagaEffects.call(origSaga.delay, backoff);
          console.log(
            "Retrying",
            func.name,
            ",",
            retries - x,
            "attempt(s) left",
          );
        } else {
          console.log("All attempts exahused calling", func.name);
          const failureAction = handler(e, action);
          if (failureAction) {
            yield origSagaEffects.put(failureAction);
          }
          return;
        }
      }
    }
    throw "This should never happen";
  }
  return wrapper;
}

export function onErrorDispatchDecorator<T: *>(
  func: T,
  handler: (Error | Response, any) => Action,
): T {
  var {take, fork, race, put, call, select} = require("redux-saga/effects");
  function* wrapper(action: Action): any {
    try {
      yield func(action);
    } catch (e) {
      const failureAction = handler(e, action);
      if (failureAction) {
        yield put(failureAction);
      }
    }
  }
  return wrapper;
}

export function catchForkError<T: *>(func: T): T {
  const isDebugging =
    !process.env.NODE_ENV || process.env.NODE_ENV === "development";
  return onErrorDispatchDecorator(func, (error) => {
    if (!isDebugging) {
      // Logueamos en modo no dev porque sino no las vemos y las queremos ver,
      // en modo dev tenemos el log de actions.
      console.log("SAGA_FAILURE", func, error);
    }
    return {type: "SAGA_FAILURE", error, func};
  });
}

export function forkForever(func: Function, backoff: number = 1000) {
  let safeFunc = catchForkError(func);
  var {delay} = require("redux-saga");
  var {take, fork, race, put, call, select} = require("redux-saga/effects");
  function* wrapper(): any {
    while (true) {
      yield call(safeFunc, ...arguments);
      yield call(delay, backoff);
      console.log("restarting fork", func);
    }
  }
  return fork(wrapper);
}

function checkStatus(response: Response): Promise<Response> {
  // https://github.com/github/fetch
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    let error: any = new Error(response.statusText);
    error.statusCode = response.status;
    throw error;
  }
}

function handleError(url, request): (Error) => Promise<Response> {
  // Reintentamos una vez si el error es "Network request failed"
  // Esto sucede cuando lockeamos el tel despues de hacer algun request.
  // Al desbloquear falla inmediatamente el primer request.
  // El reintento se hace sin handleError para no hacer un loop infinito.
  return function(error) {
    if (error.message === "Network request failed") {
      console.log("Retrying request due to *Network request failed* error");
      var f = fetch(url, request);
      return f.then(checkStatus);
    } else {
      throw error;
    }
  };
}

export function safeFetch(url: string, request: ?Object): Promise<Response> {
  var request = request || {};
  var f = fetch(url, request);
  return f.then(checkStatus).catch(handleError(url, request));
}

export function extractResponseError(error: ?Response | string): string {
  return (
    (error instanceof Response &&
      error.status === 400 &&
      safeJSONParse(error.headers.get("validation-error-json"))) ||
    defaultError
  );
}
