/**
 * @flow
 */
"use strict";

import React, {Component, PropTypes} from "react";
import actions from "../actions";
import * as types from "../actions/types";
import * as api2 from "../api2";
import {ThemeContext} from "styled-components";

export type Props = {|
  updateTradingSettings: typeof actions.user.updateTradingSettings,
  groupCount: number,
  currentGroup: number,
|};

type State = {};

export default class MyComponent extends React.Component<Props, State> {
  // static defaultProps = {}

  constructor(props: Props) {
    super(props);
    // this.state = {};
  }

  render(): React$Node {
    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <div style={{...styles.rFlex, justifyContent: "space-between"}}>
            {Array.from({length: this.props.groupCount}).map((i, idx) => (
              <div
                key={idx}
                onClick={() =>
                  this.props.updateTradingSettings({orderGroup: idx})
                }
                style={{
                  ...styles.group,
                  textAlign: "center",
                  borderColor: theme.fgColor01,
                  marginLeft: idx > 0 ? "0.5rem" : 0,
                  backgroundColor:
                    idx === this.props.currentGroup
                      ? theme.bgColor03
                      : theme.bgColor02,
                }}
              >
                {idx}
              </div>
            ))}
          </div>
        )}
      </ThemeContext.Consumer>
    );
  }
}

const styles = {
  group: {
    lineHeight: "1.2rem",
    width: "1.2rem",
    borderStyle: "solid",
    borderWidth: "0.08rem",
    cursor: "pointer",
  },
  cFlex: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  cFlexAuto: {
    display: "flex",
    flexDirection: "column",
  },
  rFlex: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
  },
  rFlexAuto: {
    display: "flex",
    flexDirection: "row",
  },
  labelText: {
    flex: 1,
  },
  itemText: {
    flex: 2,
    textAlign: "right",
  },
  headerText: {
    fontSize: "1.2rem",
    marginBottom: "0.5rem",
  },
  orderButton: {
    padding: "3rem",
    fontSize: "2rem",
  },
};
