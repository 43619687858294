/**
 * @flow
 */

import CryptoJS from "crypto-js";
import * as bybitws from "./ByBitWebSocket";
import querystring from "querystring";

export type Props = {|
  apiKey: string,
  apiSecret: string,
  endpoint: string,
|};

export type APIRequest = {|
  method: "GET" | "POST",
  path: string,
  params: Object,
|};

export type GetOrdersRequest = {|
  symbol: bybitws.Symbol,
|};

export type GetPositionsRequest = {|
  symbol: bybitws.Symbol,
|};

export type CancelOrderRequest = {|
  symbol: bybitws.Symbol,
  order_id: bybitws.OrderId,
|};
export type CancelAllOrdersRequest = {|
  symbol: bybitws.Symbol,
|};
export type ReplaceOrderRequest = {|
  symbol: bybitws.Symbol,
  p_r_qty?: string,
  p_r_price?: string,
  // Es uno o el otro ID pero le pedimos los dos para poder procesarlo facil en
  // reducers.
  // ...({|order_id: bybitws.OrderId|} | {|order_link_id: bybitws.OrderLinkId|}),
  order_id: bybitws.OrderId,
  order_link_id: bybitws.OrderLinkId,
|};
export type PlaceOrderRequest = {|
  side: "Buy" | "Sell",
  symbol: bybitws.Symbol,
  order_type: "Limit" | "Market",
  qty: number,
  time_in_force:
    | "GoodTillCancel"
    | "ImmediateOrCancel"
    | "FillOrKill"
    | "PostOnly",
  price?: number,
  reduce_only?: boolean,
  order_link_id: bybitws.OrderLinkId,

  // take_profit	false	number	Take profit price, only take effect upon opening the position
  // stop_loss	false	number	Stop loss price, only take effect upon opening the position
  // close_on_trigger	false	bool	What is a close on trigger order? For a closing order. It can only reduce your position, not increase it. If the account has insufficient available balance when the closing order is triggered, then other active orders of similar contracts will be cancelled or reduced. It can be used to ensure your stop loss reduces your position regardless of current available margin.
  // order_link_id	false	string	Customised order ID, maximum length at 36 characters, and order ID under the same agency has to be unique.
|};
export type SetTradingStopRequest = {|
  symbol: bybitws.Symbol,
  take_profit?: number,
  stop_loss?: number,
  trailing_stop?: number,
  tp_trigger_by?: "LastPrice" | "IndexPrice" | "MarkPrice",
  sl_trigger_by?: "LastPrice" | "IndexPrice" | "MarkPrice",
  new_trailing_active?: number,
|};

export type GetStopOrdersRequest = {|
  symbol: bybitws.Symbol,
|};
export type PlaceStopOrderRequest = {|
  side: "Buy" | "Sell",
  symbol: bybitws.Symbol,
  order_type: "Limit" | "Market",
  qty: string,
  time_in_force:
    | "GoodTillCancel"
    | "ImmediateOrCancel"
    | "FillOrKill"
    | "PostOnly",
  price?: string,
  base_price: string,
  stop_px: string,
  order_link_id: bybitws.OrderLinkId,
  close_on_trigger?: boolean,

  // take_profit	false	number	Take profit price
  // stop_loss	false	number	Stop loss price
  // tp_trigger_by	false	string	Take profit trigger price type, default: LastPrice
  // sl_trigger_by	false	string	Stop loss trigger price type, default: LastPrice
|};
export type ReplaceStopOrderRequest = {|
  symbol: bybitws.Symbol,
  p_r_qty?: string,
  p_r_trigger_price?: string,
  // Es uno o el otro ID pero le pedimos los dos para poder procesarlo facil en
  // reducers.
  // ...({|order_id: bybitws.OrderId|} | {|order_link_id: bybitws.OrderLinkId|}),
  stop_order_id: bybitws.OrderId,
  order_link_id: bybitws.OrderLinkId,
|};
export type CancelStopOrderRequest = {|
  symbol: bybitws.Symbol,
  stop_order_id: bybitws.OrderId,
|};

function checkStatus(response: *): * {
  // https://github.com/github/fetch
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    let error: any = new Error(response.statusText);
    error.statusCode = response.status;
    throw error;
  }
}

export default class ByBitAPI {
  props: Props;

  constructor(props: Props) {
    this.props = props;
  }

  getSignature(params: Object): string {
    var orderedParams = "";
    Object.keys(params)
      .sort()
      .forEach(function(key) {
        orderedParams += key + "=" + params[key] + "&";
      });
    orderedParams = orderedParams.substring(0, orderedParams.length - 1);
    return CryptoJS.enc.Hex.stringify(
      CryptoJS.HmacSHA256(orderedParams, this.props.apiSecret),
    );
  }

  doRequest: (APIRequest) => Promise<Response> = (request) => {
    request = JSON.parse(JSON.stringify(request)); // clone.
    request.params.api_key = this.props.apiKey;
    request.params.timestamp = (new Date().getTime() - 30 * 1000).toFixed(0);
    request.params.recv_window = 60 * 1000;
    request.params.sign = this.getSignature(request.params);
    // A fetchUrl solo lo usamos para armar el query string porque no se
    // banca url relativo sin especificar un host.
    const fetchUrl = new URL(request.path, "http://localhost");
    const fetchOptions: RequestOptions = {
      method: request.method,
      cache: "no-cache",
    };
    if (request.method === "GET") {
      Object.keys(request.params)
        .sort()
        .forEach((i) => fetchUrl.searchParams.append(i, request.params[i]));
    } else {
      fetchOptions.headers = {"Content-Type": "application/json"};
      fetchOptions.body = JSON.stringify(request.params);
    }
    // console.log('bybit api req2', this.props.endpoint + request.path + fetchUrl.search, fetchOptions.body || null);
    return fetch(
      this.props.endpoint + request.path + fetchUrl.search,
      fetchOptions,
    ).then(checkStatus);
  };

  getOrders: (GetOrdersRequest) => Promise<Array<bybitws.Order>> = (params) => {
    return this.doRequest({
      path: "/v2/private/order",
      method: "GET",
      params,
    })
      .then((r) => r.json())
      .then((r) => {
        if (r.ret_msg !== "OK") {
          throw new Error(["API error", JSON.stringify(r)]);
        } else {
          const response = [];
          Object.keys(r.result).forEach((i) => {
            let order = r.result[i];
            order.timestamp = order.updated_at;
            delete order.updated_at;
            response.push(order);
          });
          return response;
        }
      });
  };

  getWallets: () => Promise<Array<bybitws.Wallet>> = () => {
    return this.doRequest({
      path: "/v2/private/wallet/balance",
      method: "GET",
      params: {coin: "BTC"},
    })
      .then((r) => r.json())
      .then((r) => {
        if (r.ret_msg !== "OK") {
          throw new Error(["API error", JSON.stringify(r)]);
        } else {
          const response = [];
          Object.keys(r.result).forEach((i) => {
            let wallet = r.result[i];
            wallet.coin = i;
            response.push(wallet);
          });
          return response;
        }
      });
  };

  getPositions: (GetPositionsRequest) => Promise<bybitws.Position> = (
    params,
  ) => {
    return this.doRequest({
      path: "/v2/private/position/list",
      method: "GET",
      params,
    })
      .then((r) => r.json())
      .then((r) => {
        if (r.ret_msg !== "OK") {
          throw new Error(["API error", JSON.stringify(r)]);
        } else {
          return r.result;
        }
      });
  };

  getExecutions: (any) => Promise<Array<bybitws.OrderExecution>> = (params) => {
    return this.doRequest({
      path: "/v2/private/execution/list",
      method: "GET",
      params,
    })
      .then((r) => r.json())
      .then((r) => {
        if (r.ret_msg !== "OK") {
          throw new Error(["API error", JSON.stringify(r)]);
        } else {
          const response = [];
          Object.keys(r.result.trade_list).forEach((i) => {
            let execution = r.result.trade_list[i];
            execution.price = execution.exec_price;
            delete execution.exec_price;
            execution.trade_time = new Date(
              execution.trade_time_ms,
            ).toISOString();
            delete execution.trade_time_ms;
            response.push(execution);
          });
          return response;
        }
      });
  };

  getClosedPnL: (any) => Promise<any> = (params) => {
    return this.doRequest({
      path: "/v2/private/trade/closed-pnl/list",
      method: "GET",
      params,
    })
      .then((r) => r.json())
      .then((r) => {
        if (r.ret_msg !== "OK") {
          throw new Error(["API error", JSON.stringify(r)]);
        } else {
          return r.result;
        }
      });
  };

  placeOrder: (PlaceOrderRequest) => Promise<bybitws.Order> = (params) => {
    return this.doRequest({
      path: "/v2/private/order/create",
      method: "POST",
      params,
    })
      .then((r) => r.json())
      .then((r) => {
        if (r.ret_msg !== "OK") {
          throw new Error(["API error", JSON.stringify(r)]);
        } else {
          return r.result;
        }
      });
  };

  replaceOrder: (ReplaceOrderRequest) => Promise<bybitws.OrderId> = (
    params,
  ) => {
    return this.doRequest({
      path: "/v2/private/order/replace",
      method: "POST",
      params,
    })
      .then((r) => r.json())
      .then((r) => {
        if (r.ret_msg !== "OK") {
          throw new Error(["API error", JSON.stringify(r)]);
        } else {
          return r.result.order_id;
        }
      });
  };

  cancelOrder: (CancelOrderRequest) => Promise<void> = (params) => {
    return this.doRequest({
      path: "/v2/private/order/cancel",
      method: "POST",
      params,
    })
      .then((r) => r.json())
      .then((r) => {
        if (r.ret_msg !== "OK") {
          throw new Error(["API error", JSON.stringify(r)]);
        }
      });
  };

  cancelAllOrders: (CancelAllOrdersRequest) => Promise<void> = (params) => {
    return this.doRequest({
      path: "/v2/private/order/cancelAll",
      method: "POST",
      params,
    })
      .then((r) => r.json())
      .then((r) => {
        if (r.ret_msg !== "OK") {
          throw new Error(["API error", JSON.stringify(r)]);
        }
      });
  };

  setTradingStop: (SetTradingStopRequest) => Promise<void> = (params) => {
    return this.doRequest({
      path: "/v2/private/position/trading-stop",
      method: "POST",
      params,
    })
      .then((r) => r.json())
      .then((r) => {
        if (r.ret_msg !== "OK") {
          throw new Error(["API error", JSON.stringify(r)]);
        }
      });
  };

  getStopOrders: (GetStopOrdersRequest) => Promise<Array<bybitws.StopOrder>> = (
    params,
  ) => {
    return this.doRequest({
      path: "/v2/private/stop-order",
      method: "GET",
      params,
    })
      .then((r) => r.json())
      .then((r) => {
        if (r.ret_msg !== "OK") {
          throw new Error(["API error", JSON.stringify(r)]);
        } else {
          const response = [];
          Object.keys(r.result).forEach((i) => {
            let order = r.result[i];
            order.timestamp = order.updated_at;
            order.trigger_price = order.stop_px;
            delete order.updated_at;
            delete order.stop_px;
            response.push(order);
          });
          return response;
        }
      });
  };
  placeStopOrder: (PlaceStopOrderRequest) => Promise<bybitws.OrderId> = (
    params,
  ) => {
    return this.doRequest({
      path: "/v2/private/stop-order/create",
      method: "POST",
      params,
    })
      .then((r) => r.json())
      .then((r) => {
        if (r.ret_msg !== "OK") {
          throw new Error(["API error", JSON.stringify(r)]);
        } else {
          return r.result.StopOrder_id;
        }
      });
  };
  replaceStopOrder: (ReplaceStopOrderRequest) => Promise<bybitws.OrderId> = (
    params,
  ) => {
    return this.doRequest({
      path: "/v2/private/stop-order/replace",
      method: "POST",
      params,
    })
      .then((r) => r.json())
      .then((r) => {
        if (r.ret_msg !== "OK") {
          throw new Error(["API error", JSON.stringify(r)]);
        } else {
          return r.result.StopOrder_id;
        }
      });
  };
  cancelStopOrder: (CancelStopOrderRequest) => Promise<void> = (params) => {
    return this.doRequest({
      path: "/v2/private/stop-order/cancel",
      method: "POST",
      params,
    })
      .then((r) => r.json())
      .then((r) => {
        if (r.ret_msg !== "OK") {
          throw new Error(["API error", JSON.stringify(r)]);
        }
      });
  };
}
