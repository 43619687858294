/**
 * @flow
 */

import * as types from "../actions/types";
import actions from "../actions";

type _ReducerUserState = {|
  +isLoggedIn: boolean,
  +apiEndpoint: ?string,
  +token: ?string,
  +user: ?string,
  +deviceId: ?string,
  +tradingSettings: types.TradingSettings,
|};
export type State = _ReducerUserState & $Shape<_ReducerUserState>;

const hashParams = new URLSearchParams(window.location.hash);

export const initialState: State = {
  isLoggedIn: false,
  apiEndpoint: null,
  token: null,
  user: null,
  deviceId: null,
  tradingSettings: {
    alarms: [],
    orderSize: localStorage.getItem("orderSize") || "",
    orderSizeSelectorPage: 0,
    autoOrderSize: "",
    stepSize: localStorage.getItem("orderStep") || "",
    autoStopLossPercentage: null,
    autoTrailingStopProfitTakerTriggerPercentage: null,
    autoTrailingStopProfitTakerTrailingAmount: 250,
    orderGroup: 0,
    audioEnabled: hashParams.has("audio")
      ? hashParams.get("audio") === "1"
      : false,
    chartOnlyEnabled: hashParams.has("chartOnly")
      ? hashParams.get("chartOnly") === "1"
      : false,
    chartEnabled: hashParams.get("chartOnly")
      ? hashParams.get("chartOnly") === "1"
      : hashParams.has("chart")
      ? hashParams.get("chart") === "1"
      : false,
    dangerousEnabled: hashParams.has("dangerous")
      ? hashParams.get("dangerous") === "1"
      : false,
    executionIndicators: hashParams.has("indicators")
      ? hashParams.get("indicators") === "1"
      : false,
    addStopOrderFormDialogPrice: null,
    addStopOrderFormDialogSize: null,
    addStopOrderFormDialogBasePrice: null,
    addStopOrderFormDialogReduceOnly: true,
  },
};

export default function reducer(
  state: State = initialState,
  action: types.Action,
): State {
  if (action.type === "LOGIN_SUCCESS") {
    let {user, token, apiEndpoint} = action;
    return {
      ...state,
      deviceId: state.deviceId
        ? state.deviceId
        : Math.round(Math.random() * 1000000000) + "",
      isLoggedIn: true,
      user,
      token,
      apiEndpoint,
    };
  } else if (action.type === "LOGOUT_SUCCESS") {
    return initialState;
  } else if (action.type === "UPDATE_TRADING_SETTINGS") {
    const {orderSize, stepSize} = action.settings;
    if (orderSize) {
      setTimeout(() => localStorage.setItem("orderSize", orderSize));
    }
    if (stepSize) {
      setTimeout(() => localStorage.setItem("orderStep", stepSize));
    }
    return {
      ...state,
      tradingSettings: {
        ...state.tradingSettings,
        ...action.settings,
      },
    };
  }
  return state;
}
