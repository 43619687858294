/**
 * @flow
 */
"use strict";

import React, {Component, PropTypes} from "react";
import actions from "../actions";
import * as api2 from "../api2";
import * as types from "../actions/types";
import {ThemeContext} from "styled-components";
import {Panel} from "./StyledComponents";
import SettingsInputField from "../containers/SettingsInputFieldContainer";

export type Props = {
  account: types.ByBitAccountState,
  settings: types.TradingSettings,
  updateTradingSettings: typeof actions.user.updateTradingSettings,
  placeStopMarketOrder: typeof actions.bybit.placeStopMarketOrder,
};

type State = {};

export default class MyComponent extends React.Component<Props, State> {
  // static defaultProps = {}

  constructor(props: Props) {
    super(props);
    // this.state = {
    // };
  }

  onSubmit: (any) => void = (event) => {
    const {
      addStopOrderFormDialogBasePrice,
      addStopOrderFormDialogPrice,
      addStopOrderFormDialogSize,
      addStopOrderFormDialogReduceOnly,
    } = this.props.settings;
    if (
      addStopOrderFormDialogBasePrice &&
      addStopOrderFormDialogPrice &&
      addStopOrderFormDialogSize
    ) {
      this.props.placeStopMarketOrder({
        side:
          parseInt(addStopOrderFormDialogPrice) >
          addStopOrderFormDialogBasePrice
            ? "Buy"
            : "Sell",
        base_price: addStopOrderFormDialogBasePrice.toString(),
        stop_px: addStopOrderFormDialogPrice,
        symbol: this.props.account.symbol,
        qty: addStopOrderFormDialogSize,
        close_on_trigger: addStopOrderFormDialogReduceOnly,
      });
      this.resetForm();
    }
    event.preventDefault();
  };

  resetForm: () => void = () => {
    this.props.updateTradingSettings({
      addStopOrderFormDialogBasePrice: null,
      addStopOrderFormDialogPrice: null,
      addStopOrderFormDialogSize: null,
      addStopOrderFormDialogReduceOnly: true,
    });
  };

  render(): React$Node {
    const settings = this.props.settings;
    if (settings.addStopOrderFormDialogPrice == null) {
      return null;
    }
    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <Panel>
            <div style={styles.headerText}>{"Add Stop Order"}</div>
            <form onSubmit={this.onSubmit}>
              <table>
                <tbody>
                  <tr>
                    <td>Price</td>
                    <td>
                      <SettingsInputField
                        keyName={"addStopOrderFormDialogPrice"}
                        save={true}
                        adapter={(txt) => txt.replace(/\D/, "")}
                        style={{width: 40}}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Size</td>
                    <td>
                      <SettingsInputField
                        keyName={"addStopOrderFormDialogSize"}
                        save={true}
                        autoFocus={true}
                        adapter={(txt) => txt.replace(/\D/, "")}
                        style={{width: 40}}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Reduce Only</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={
                          this.props.settings.addStopOrderFormDialogReduceOnly
                        }
                        onChange={() =>
                          this.props.updateTradingSettings({
                            addStopOrderFormDialogReduceOnly: !this.props
                              .settings.addStopOrderFormDialogReduceOnly,
                          })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input type="submit" value="Submit" />
                    </td>
                    <td>
                      <button
                        type="submit"
                        value="Cancel"
                        onClick={this.resetForm}
                      >
                        Cancel
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
          </Panel>
        )}
      </ThemeContext.Consumer>
    );
  }
}

const styles = {
  cFlex: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  cFlexAuto: {
    display: "flex",
    flexDirection: "column",
  },
  rFlex: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
  },
  rFlexAuto: {
    display: "flex",
    flexDirection: "row",
  },
  labelText: {
    flex: 1,
  },
  itemText: {
    flex: 2,
    textAlign: "right",
  },
  headerText: {
    fontSize: "1.2rem",
    marginBottom: "0.5rem",
  },
  orderButton: {
    padding: "3rem",
    fontSize: "2rem",
  },
};
