/**
 * @flow
 */
"use strict";

import * as React from "react";
import actions from "../actions";
import * as api2 from "../api2";
import * as types from "../actions/types";
import * as tvutils from "../lib/TradingViewUtils";
import {ThemeContext} from "styled-components";
import * as colorUtils from "../lib/normalizeColor";

const DEFAULT_VOLUME = 1;
function playAudio(path: string, volume: number = DEFAULT_VOLUME) {
  const audio = new Audio(window.location.origin + path);
  audio.volume = volume;
  audio.play();
}

export type Props = {|
  tvWidget: any,
  alarm: number,
  settings: types.TradingSettings,
  account: types.ByBitAccountState,
  updateTradingSettings: typeof actions.user.updateTradingSettings,
|};

export default class MyComponent extends React.PureComponent<Props> {
  static contextType: React$Context<any> = ThemeContext;
  nextAlert: number = 0;
  line: any = null;
  side: "Top" | "Bottom";

  componentDidMount() {
    const color = "#070";
    this.nextAlert = 0;
    this.side = this.props.alarm < this.getLastTradedPrice() ? "Bottom" : "Top";
    this.line = this.props.tvWidget
      .chart()
      .createOrderLine()
      .setLineLength(100)
      .setText(this.getLineText())
      .setPrice(this.getPrice())
      .setQuantity(null)
      .onMove(() => this.updateAlarm())
      .onCancel(() => this.removeAlarm())
      .setLineStyle(0)
      .setLineWidth(1)
      .setLineColor(color)
      .setBodyBorderColor(color)
      .setBodyTextColor(color)
      .setBodyBackgroundColor("#ccc")
      .setQuantityBorderColor(color)
      .setQuantityBackgroundColor(color)
      .setCancelButtonBorderColor(color)
      .setCancelButtonBackgroundColor(color)
      .setCancelButtonIconColor("#ccc");
  }

  componentWillUnmount() {
    if (this.line != null) {
      this.line.remove();
      this.line = null;
    }
  }

  componentDidUpdate() {
    if (this.line != null) {
      this.line.setText(this.getLineText()).setPrice(this.getPrice());
    }
    const lastTradedPrice = this.getLastTradedPrice();
    if (
      (this.side === "Bottom" && this.props.alarm > lastTradedPrice) ||
      (this.side === "Top" && this.props.alarm < lastTradedPrice)
    ) {
      const now = new Date().getTime();
      if (this.nextAlert < now) {
        playAudio(
          this.side === "Bottom" ? "/descending.wav" : "/ascending.wav",
        );
      }
      this.side = this.props.alarm < lastTradedPrice ? "Bottom" : "Top";
      this.nextAlert = now + 5000;
    }
  }

  getLastTradedPrice(): number {
    return this.props.account.instrumentInfo.last_price_e4 / 1e4;
  }

  getPrice(): number {
    return this.props.alarm;
  }

  getLineText(): string {
    return "ALERT";
  }

  updateAlarm: () => void = () => {
    this.props.updateTradingSettings({
      alarms: [
        ...this.props.settings.alarms.filter((i) => i !== this.props.alarm),
        this.line.getPrice(),
      ],
    });
  };

  removeAlarm: () => void = () => {
    this.props.updateTradingSettings({
      alarms: [
        ...this.props.settings.alarms.filter((i) => i !== this.props.alarm),
      ],
    });
  };

  render(): React$Node {
    return null;
  }
}
