/**
 * @flow
 */

import * as types from "../actions/types";

export const TAKER_FEE = 0.06;
export const MAKER_FEE = 0.01;

export function buildOrderBook(
  orderBook: types.ByBitOrderBook,
  round: number,
): types.OrderBook {
  const buyEntries: Array<types.bybitws.BookEntry> = Array.from(
    orderBook.Buy.values(),
  ).sort(
    (a: types.bybitws.BookEntry, b: types.bybitws.BookEntry) => b.id - a.id,
  );
  const bestBuy = buyEntries[0];

  const sellEntries: Array<types.bybitws.BookEntry> = Array.from(
    orderBook.Sell.values(),
  ).sort(
    (a: types.bybitws.BookEntry, b: types.bybitws.BookEntry) => a.id - b.id,
  );
  const bestSell = sellEntries[0];

  let bidsSlots = new Map();
  let bidsDepth = 0;
  buyEntries.forEach((i) => {
    const slotPrice = Math.floor(parseFloat(i.price) / round) * round;
    const slot = bidsSlots.get(slotPrice) || {
      size: 0,
      cumulative: 0,
      price: slotPrice.toFixed(0),
      id: slotPrice,
    };
    bidsDepth += i.size;
    slot.size += i.size;
    slot.cumulative = bidsDepth;
    bidsSlots.set(slotPrice, slot);
  });

  let asksSlots = new Map();
  let asksDepth = 0;
  sellEntries.forEach((i) => {
    const slotPrice = Math.ceil(parseFloat(i.price) / round) * round;
    const slot = asksSlots.get(slotPrice) || {
      size: 0,
      cumulative: 0,
      price: slotPrice.toFixed(0),
      id: slotPrice,
    };
    asksDepth += i.size;
    slot.size += i.size;
    slot.cumulative = asksDepth;
    asksSlots.set(slotPrice, slot);
  });

  return {
    bids: Array.from(bidsSlots.values()),
    asks: Array.from(asksSlots.values()),
    bidsDepth,
    asksDepth,
    bestBuy,
    bestSell,
    bestBuyAmount: parseFloat(bestBuy?.price || "0"),
    bestSellAmount: parseFloat(bestSell?.price || "0"),
  };
}

function getOrderBestAmounts({
  account,
  openOrders,
  orderBook,
}: {
  account: types.ByBitAccountState,
  openOrders: Array<types.bybitws.Order>,
  orderBook: types.OrderBook,
}): {|
  bestBuyAmount: Map<number, number>,
  bestSellAmount: Map<number, number>,
|} {
  const bestBuyAmount: Map<number, number> = new Map();
  const bestSellAmount: Map<number, number> = new Map();
  const buyPrices: Map<number, Array<number>> = new Map();
  const sellPrices: Map<number, Array<number>> = new Map();
  const getKey: (string) => number = (i) => {
    const parts = i.split("_");
    return parseInt(parts[parts.length - 1]);
  };

  Array.from(account.pendingOrders.entries() || [])
    .filter(([k, i]) => i.side === "Buy")
    .forEach(([k, {price}]) => {
      if (price) {
        const key = getKey(k);
        const arr = buyPrices.get(key) || [];
        arr.push(price);
        buyPrices.set(key, arr);
      }
    });

  openOrders
    .filter((i) => i.side === "Buy")
    .forEach((i) => {
      const key = getKey(i.order_link_id);
      const arr = buyPrices.get(key) || [];
      arr.push(parseFloat(i.price));
      buyPrices.set(key, arr);
    });

  Array.from(account.pendingOrders.entries() || [])
    .filter(([k, i]) => i.side === "Sell")
    .forEach(([k, {price}]) => {
      if (price) {
        const key = getKey(k);
        const arr = sellPrices.get(key) || [];
        arr.push(price);
        sellPrices.set(key, arr);
      }
    });

  openOrders
    .filter((i) => i.side === "Sell")
    .forEach((i) => {
      const key = getKey(i.order_link_id);
      const arr = sellPrices.get(key) || [];
      arr.push(parseFloat(i.price));
      sellPrices.set(key, arr);
    });

  Array.from(buyPrices.entries()).forEach(([k, v]) =>
    bestBuyAmount.set(k, v.sort()[0]),
  );

  Array.from(sellPrices.entries()).forEach(([k, v]) =>
    bestSellAmount.set(k, v.sort()[v.length - 1]),
  );

  return {
    bestBuyAmount,
    bestSellAmount,
  };
}

export function buildOpenOrdersInfo(
  account: types.ByBitAccountState,
  orderBook: types.OrderBook,
): types.OpenOrdersInfo {
  const sumAmount = {Sell: 0, Buy: 0};
  const sumContract = {Sell: 0, Buy: 0};
  const diffMap = new Map();
  const sortedOrders = Array.from(account.orders.values() || [])
    .filter((i) => i.symbol === account.symbol)
    .filter((i) => {
      let active = types.bybitws.OrderActiveStatusSet.has(i.order_status);
      if (active) {
        const price = parseFloat(i.price);
        sumContract[i.side] += parseInt(i.leaves_qty);
        sumAmount[i.side] += parseFloat(i.price) * parseInt(i.leaves_qty);
        diffMap.set(
          i.order_id,
          i.side === "Buy"
            ? orderBook.bestBuyAmount - price
            : price - orderBook.bestSellAmount,
        );
        return true;
      }
      return false;
    })
    .sort(
      (a, b) =>
        (diffMap.has(a.order_id) ? diffMap.get(a.order_id) || 0 : Infinity) -
        (diffMap.has(b.order_id) ? diffMap.get(b.order_id) || 0 : -Infinity),
    );
  const position = account.positions.get(account.symbol);
  const averageBuy = sumContract.Buy > 0 ? sumAmount.Buy / sumContract.Buy : 0;
  const averageSell =
    sumContract.Sell > 0 ? sumAmount.Sell / sumContract.Sell : 0;
  return {
    diffMap,
    sortedOrders,
    stopOrders: Array.from(account.stopOrders.values()).filter(
      (i) => i.order_status === "Untriggered",
    ),
    totalBuy: sumContract.Buy,
    totalSell: sumContract.Sell,
    averageBuy,
    averageSell,
    projectedBuyPnL:
      position?.side === "Sell" && averageBuy > 0
        ? ((parseFloat(position.entry_price) - averageBuy) /
            parseFloat(position.entry_price)) *
          100 *
          100
        : null,
    projectedSellPnL:
      position?.side === "Buy" && averageSell > 0
        ? ((averageSell - parseFloat(position.entry_price)) /
            parseFloat(position.entry_price)) *
          100 *
          100
        : null,
    ...getOrderBestAmounts({account, openOrders: sortedOrders, orderBook}),
  };
}

export function calculateStopLoss(
  position: types.bybitws.Position,
  stopLossPercentage: number,
  takerFee: number,
): number {
  let stopLoss = 0;
  if (stopLossPercentage > 0) {
    let entry = parseFloat(position.entry_price);
    if (position.side === "Buy") {
      entry += (entry * takerFee) / 100;
    } else {
      entry -= (entry * takerFee) / 100;
    }
    if (position.side === "Buy") {
      stopLoss = entry - entry * (stopLossPercentage / 100 / 100);
    } else {
      stopLoss = entry + entry * (stopLossPercentage / 100 / 100);
    }
  }
  return Math.round(stopLoss);
}
