/**
 * @flow
 */
"use strict";

import type {Bar, Resolution} from "./TradingViewUtils";
import {BarsCache} from "./TradingViewBarsCache";

export function barsGet({
  ticker,
  resolution,
  from,
  to,
}: {
  ticker: string,
  resolution: Resolution,
  from: number,
  to: number,
}): Promise<Array<Bar>> {
  return new Promise(async (resolve, cancel) => {
    const cache = new BarsCache({ticker, resolution});
    // cache.clear(resolution);
    const cachedBars = await cache.get({
      from,
      to,
    });
    // console.log('got', cachedBars.length, 'from the cache');
    if (cachedBars.length > 0) {
      from = cachedBars[cachedBars.length - 1].time / 1000 + 1;
    }
    const bars = [];
    const isMarkPrice = ticker.startsWith(".");
    while (true) {
      // console.log("fetch", ticker, resolution, from, to);
      let response = null;
      if (isMarkPrice) {
        response = await fetch(
          `v2/public/mark-price-kline?symbol=${ticker.slice(
            1,
          )}&interval=${formatResolution(resolution)}&from=${from}`,
        );
      } else {
        response = await fetch(
          `v2/public/kline/list?symbol=${ticker}&interval=${formatResolution(
            resolution,
          )}&from=${from}`,
        );
      }
      response = await response.json();
      if (response && response.ret_msg === "OK") {
        bars.push(
          ...response.result
            .filter((i) => (isMarkPrice ? i.start_at : i.open_time <= to))
            .map((i) => ({
              time: isMarkPrice ? i.start_at * 1000 : i.open_time * 1000,
              high: parseFloat(i.high),
              low: parseFloat(i.low),
              open: parseFloat(i.open),
              close: parseFloat(i.close),
              volume: parseFloat(i.volume),
            })),
        );
        if (response.result.length >= 200) {
          const lastResult = response.result[response.result.length - 1];
          from = (isMarkPrice ? lastResult.start_at : lastResult.open_time) + 1;
          continue;
        } else {
          break;
        }
      } else {
        cancel("getBars: api returned error");
      }
    }
    // Cacheamos resultados menos el ultimo.
    cache.set(bars.slice(0, -1));
    resolve([...cachedBars, ...bars]);
  });
}

function formatResolution(resolution) {
  const reg = /^1([A-Z])$/;
  const match = reg.exec(resolution);
  const fResolution = match ? match[1] : resolution;
  return fResolution;
}
