/**
 * @flow
 */
"use strict";

import * as React from "react";
import actions from "../actions";
import * as api2 from "../api2";
import * as types from "../actions/types";
import * as tvutils from "../lib/TradingViewUtils";
import {ThemeContext} from "styled-components";
import * as colorUtils from "../lib/normalizeColor";

export type Props = {|
  tvWidget: any,
  position: types.bybitws.Position,
  positionExtraInfo: types.ByBitPositionExtraInfo,
|};

export default class MyComponent extends React.PureComponent<Props> {
  static contextType: React$Context<any> = ThemeContext;
  line: any = null;

  componentDidMount() {
    const color = colorUtils.rgba2rgb({
      background: this.context.bgColor04,
      foreground:
        this.props.position.side === "Buy"
          ? this.context.buyColor
          : this.context.sellColor,
    });
    this.line = this.props.tvWidget
      .chart()
      .createOrderLine()
      .setLineLength(100)
      .setText(this.getLineText())
      .setPrice(this.getPrice())
      .setQuantity(null)
      .setLineStyle(1)
      .setLineWidth(2)
      .setLineColor(color)
      .setBodyBorderColor(color)
      .setBodyTextColor(color)
      .setBodyBackgroundColor("#ccc")
      .setQuantityBorderColor(color)
      .setQuantityBackgroundColor(color)
      .setCancelButtonBorderColor(color)
      .setCancelButtonBackgroundColor("#1E1F25")
      .setCancelButtonIconColor(color);
  }

  componentWillUnmount() {
    if (this.line != null) {
      this.line.remove();
      this.line = null;
    }
  }

  componentDidUpdate() {
    if (this.line != null) {
      this.line
        .setText(this.getLineText())
        .setPrice(this.getPrice());
        // .setQuantity(this.props.position.size);
    }
  }

  getPrice(): number {
    return this.props.positionExtraInfo.breakEvenPrice;
  }

  getLineText(): string {
    return "BREAKEVEN";
  }

  render(): React$Node {
    return null;
  }
}
