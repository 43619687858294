/**
 * @flow
 */

import * as IOEffects from "redux-saga/effects";
import bybitSaga from "./bybit.js";
import soundSaga from "./sound.js";

function* rootSaga(): Generator<any, any, any> {
  yield IOEffects.all([bybitSaga(), soundSaga()]);
}

export default rootSaga;