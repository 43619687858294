/**
 * @flow
 */
"use strict";

import * as React from "react";
import actions from "../actions";
import * as api2 from "../api2";
import * as types from "../actions/types";
import * as tvutils from "../lib/TradingViewUtils";
import {ThemeContext} from "styled-components";
import * as colorUtils from "../lib/normalizeColor";

export type Props = {|
  tvWidget: any,
  order: types.bybitws.StopOrder,
  replaceStopOrder: typeof actions.bybit.replaceStopOrder,
  cancelStopOrder: typeof actions.bybit.cancelStopOrder,
|};

export default class MyComponent extends React.PureComponent<Props> {
  static contextType: React$Context<any> = ThemeContext;
  line: any = null;

  getText(): string {
    return 'STOP';
    // return this.props.order.stop_order_type.toUpperCase();
    // + ' ON ' + this.props.order.trigger_by.toUpperCase() ;
  }

  componentDidMount() {
    const color = colorUtils.rgba2rgb({
      background: this.context.bgColor04,
      foreground:
        this.props.order.side === "Buy"
          ? this.context.buyColor
          : this.context.sellColor,
    });
    this.line = this.props.tvWidget
      .chart()
      .createOrderLine()
      .setLineLength(100)
      .setText(this.getText())
      .setPrice(this.props.order.trigger_price)
      .setQuantity(this.props.order.qty)
      .onMove(() => {
        this.line.setText("Amending ...");
        const price = this.line.getPrice();
        this.props.replaceStopOrder(
          {
            symbol: this.props.order.symbol,
            stop_order_id: this.props.order.order_id,
            order_link_id: this.props.order.order_link_id,
            p_r_trigger_price: price,
          },
          {
            ...this.props.order,
            price,
          },
        );
      })
      .onCancel(() => {
        this.line.setText("Cancelling ...");
        this.props.cancelStopOrder({
          symbol: this.props.order.symbol,
          stop_order_id: this.props.order.order_id,
        });
      })
      .setLineStyle(2)
      .setLineWidth(1)
      .setLineColor(color)
      .setBodyBorderColor(color)
      .setBodyTextColor("#FFFFFF")
      .setBodyBackgroundColor("#1E1F25")
      .setQuantityTextColor("#FFFFFF")
      .setQuantityBorderColor(color)
      .setQuantityBackgroundColor(color)
      .setCancelButtonBorderColor(color)
      .setCancelButtonBackgroundColor("#1E1F25")
      .setCancelButtonIconColor("#FFFFFF");
  }

  componentWillUnmount() {
    if (this.line != null) {
      this.line.remove();
      this.line = null;
    }
  }

  componentDidUpdate() {
    if (this.line != null) {
      this.line
        .setText(this.getText())
        .setPrice(this.props.order.trigger_price)
        .setQuantity(this.props.order.qty);
    }
  }

  render(): React$Node {
    return null;
  }
}
