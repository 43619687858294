/**
 * @flow
 */

import React, {Component, PropTypes} from "react";
import actions from "../actions";
import * as api2 from "../api2";
import * as types from "../actions/types";
import * as bybitutils from "../lib/ByBitUtils";
import {ThemeContext} from "styled-components";
import {Panel, OrderButton, OrderSizeButton} from "./StyledComponents";
import OrderBookPanel from "./OrderBookPanel";
import AddStopOrderPanel from "../containers/AddStopOrderPanelContainer";
import PlaceOrderPanel from "../containers/PlaceOrderPanelContainer";
import CancelOrderPanel from "../containers/CancelOrderPanelContainer";
import TradingViewChart from "../containers/TradingViewChartContainer";

export type Props = {|
  connectByBit: typeof actions.bybit.connect,
  cancelOrder: typeof actions.bybit.cancelOrder,
  cancelAllOrders: typeof actions.bybit.cancelAllOrders,
  updateTradingSettings: typeof actions.user.updateTradingSettings,
  settings: types.TradingSettings,
  account: types.ByBitAccountState,
  book: types.OrderBook,
  openOrdersInfo: types.OpenOrdersInfo,
|};

type State = {
  apiKey: string,
  apiSecret: string,
};

export default class MyComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      apiKey: localStorage.getItem("apiKey") || "",
      apiSecret: localStorage.getItem("apiSecret") || "",
    };
  }

  componentDidMount() {
    this.connect();
  }

  isAPIFormComplete: () => boolean = () => {
    return !!(this.state.apiKey && this.state.apiSecret);
  };

  connect: () => void = () => {
    if (this.isAPIFormComplete()) {
      localStorage.setItem("apiKey", this.state.apiKey);
      localStorage.setItem("apiSecret", this.state.apiSecret);
      this.props.connectByBit({
        symbol: this.props.account.symbol,
        apiKey: this.state.apiKey,
        apiSecret: this.state.apiSecret,
      });
    }
  };

  renderAPIKeyForm(): React$Node {
    return (
      <Panel>
        <form onSubmit={this.connect}>
          <div>
            <label>API Key</label>
            <input
              type={"password"}
              value={this.state.apiKey}
              onChange={(e) => this.setState({apiKey: e.target.value})}
            />
          </div>
          <div>
            <label>API Secret</label>
            <input
              type={"password"}
              value={this.state.apiSecret}
              onChange={(e) => this.setState({apiSecret: e.target.value})}
            />
          </div>
          <button disabled={!this.isAPIFormComplete()}>Connect</button>
        </form>
        <button
          onClick={() => {
            localStorage.clear();
            this.setState({apiSecret: "", apiKey: ""});
          }}
        >
          Clear
        </button>
      </Panel>
    );
  }

  renderOpenOrders(): React$Node {
    if (this.props.openOrdersInfo.sortedOrders.length <= 0) {
      return null;
    }
    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <Panel>
            <button
              onClick={() =>
                this.props.cancelAllOrders({
                  symbol: this.props.account.symbol,
                })
              }
              style={{float: "right"}}
            >
              Cancel All
            </button>
            <div style={styles.headerText}>{"Open Orders"}</div>
            <table style={{width: "100%"}}>
              <thead>
                <tr>
                  <th style={{textAlign: "left"}}>Symbol</th>
                  <th>Qty</th>
                  <th>Price</th>
                  <th>Diff</th>
                  <th>Type</th>
                  <th>Status</th>
                  <th>Group</th>
                </tr>
              </thead>
              <tbody>
                {this.props.openOrdersInfo.sortedOrders.map((i) => (
                  <tr key={i.order_id}>
                    <td
                      style={{
                        textAlign: "left",
                        fontWeight: "bold",
                        color:
                          i.side === "Buy" ? theme?.buyColor : theme?.sellColor,
                      }}
                    >
                      {i.symbol}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        color:
                          i.side === "Buy" ? theme?.buyColor : theme?.sellColor,
                      }}
                    >
                      {i.leaves_qty}
                    </td>
                    <td style={{textAlign: "center"}}>
                      {parseFloat(i.price).toFixed(2)}
                    </td>
                    <td style={{textAlign: "center"}}>
                      {this.props.openOrdersInfo.diffMap
                        .get(i.order_id)
                        ?.toFixed(2) || ""}
                    </td>
                    <td style={{textAlign: "center"}}>{i.order_type}</td>
                    <td style={{textAlign: "center"}}>{i.order_status}</td>
                    <td style={{textAlign: "center"}}>
                      {i.order_link_id.split("_").slice(-1)[0]}
                    </td>
                    <td style={{textAlign: "right"}}>
                      <button
                        onClick={() =>
                          this.props.cancelOrder({
                            symbol: i.symbol,
                            order_id: i.order_id,
                          })
                        }
                      >
                        Cancel
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Panel>
        )}
      </ThemeContext.Consumer>
    );
  }

  renderPositions(): React$Node {
    let openPositions = Array.from(
      this.props.account.positions.values() || [],
    ).filter((i) => i.side !== "None");
    if (openPositions.length <= 0) {
      return null;
    }
    const lastTradedPrice =
      this.props.account.instrumentInfo.last_price_e4 / 1e4;
    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <Panel>
            <div style={styles.headerText}>{"Open Positions"}</div>
            <table style={{width: "100%"}}>
              <thead>
                <tr>
                  <th style={{textAlign: "left"}}>Symbol</th>
                  <th>Qty</th>
                  <th>Entry</th>
                  <th>Diff</th>
                  <th>Liq Price</th>
                  <th>Unrl PNL</th>
                  <th>Rlz PNL</th>
                  {/* <th style={{textAlign: "right"}}>Close</th> */}
                </tr>
              </thead>
              <tbody>
                {openPositions.map((i) => {
                  // https://help.bybit.com/hc/en-us/articles/900000404726 */}
                  // Long | Unrealized P&L = Contract Qty x [(1/Avg Entry Price) - (1/Last Traded Price)]
                  // Short | Unrealized P&L = Contract Qty x [(1/Last Traded Price) - (1/Avg Entry Price)]
                  const pnl = lastTradedPrice
                    ? i.side === "Buy"
                      ? i.size *
                        (1 / parseFloat(i.entry_price) - 1 / lastTradedPrice)
                      : i.size *
                        (1 / lastTradedPrice - 1 / parseFloat(i.entry_price))
                    : null;
                  // Calculamos el margen inicial porque lo que manda el
                  // websocket en position_margin es distinto al del api.
                  // https://help.bybit.com/hc/en-us/articles/360039261174-Initial-Margin-Inverse-Contract-
                  const initialMargin =
                    i.size / (parseFloat(i.entry_price) * parseInt(i.leverage));
                  // Fee to close = ( Qty / Bankruptcy Price ) x 0.075%
                  // NOTA: tuve que agregarle un / 100 mas de lo que en teoria dice la formula.
                  const feeToClose =
                    (i.size / parseFloat(i.bust_price)) * (0.075 / 100 / 100);
                  // Position Margin = Initial margin + Fee to close
                  const positionMargin = initialMargin + feeToClose;
                  // Unrealized P&L% = [ Position's unrealized P&L / Position Margin ] x 100%
                  const pnlPercentage = pnl ? (pnl / positionMargin) * 100 : 0;

                  const realizedPnL = this.props.account.positionsExtraInfo.get(i.symbol)?.realizedPnL || (pnl !== null ? 0 : null);

                  const pnlWithFee = pnl !== null ? pnl - (i.size * bybitutils.TAKER_FEE) / 100 / this.props.book.bestBuyAmount: null;
                  return (
                    <tr key={i.side}>
                      <td
                        style={{
                          textAlign: "left",
                          fontWeight: "bold",
                          color:
                            i.side === "Buy"
                              ? theme?.buyColor
                              : theme?.sellColor,
                        }}
                      >
                        {i.symbol}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          color:
                            i.side === "Buy"
                              ? theme?.buyColor
                              : theme?.sellColor,
                        }}
                      >
                        {i.size}
                      </td>
                      <td style={{textAlign: "center"}}>
                        {parseFloat(i.entry_price).toFixed(2)}
                      </td>
                      <td style={{textAlign: "center"}}>
                        {(i.side === "Buy"
                          ? this.props.book.bestBuyAmount -
                            parseFloat(i.entry_price)
                          : parseFloat(i.entry_price) -
                            this.props.book.bestSellAmount
                        ).toFixed(2)}
                      </td>
                      <td style={{textAlign: "center"}}>
                        {parseFloat(i.liq_price).toFixed(2)}
                      </td>
                      <td style={{textAlign: "center"}}>
                        {pnl !== null ? (
                          <div
                            style={{
                              fontWeight: "bold",
                              color:
                                pnl >= 0 ? theme?.winColor : theme?.loseColor,
                            }}
                          >
                            <div>
                              {pnl.toFixed(8)} {i.symbol.slice(0, 3)}
                            </div>
                            <div>
                              {(pnl * this.props.book.bestBuyAmount).toFixed(2)}{" "}
                              USD
                            </div>
                            {/* <div>{pnlPercentage.toFixed(2)}%</div> */}
                            {pnlWithFee !== null ?
                            <div>
                              {pnlWithFee.toFixed(8)} {i.symbol.slice(0, 3)}
                            </div>
                            : null}
                            {pnlWithFee !== null ?
                            <div>
                              {(pnlWithFee * this.props.book.bestBuyAmount).toFixed(2)}{" "}
                              USD
                            </div>
                            : null}
                          </div>
                        ) : null}
                      </td>
                      <td style={{textAlign: "center"}}>
                        {realizedPnL !== null ? (
                          <div
                            style={{
                              fontWeight: "bold",
                              color:
                                realizedPnL >= 0 ? theme?.winColor : theme?.loseColor,
                            }}
                          >
                            <div>
                              {realizedPnL.toFixed(8)} {i.symbol.slice(0, 3)}
                            </div>
                            <div>
                              {(realizedPnL * this.props.book.bestBuyAmount).toFixed(2)}{" "}
                              USD
                            </div>
                            {/* <div>{pnlPercentage.toFixed(2)}%</div> */}
                          </div>
                        ) : null}
                      </td>
                      {/* <td style={{textAlign: "right"}}>
                        <button onClick={() => alert("No esta implementado!")}>
                          Market
                        </button>
                        <button onClick={() => alert("No esta implementado!")}>
                          Limit
                        </button>
                      </td> */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Panel>
        )}
      </ThemeContext.Consumer>
    );
  }

  renderWallet(): React$Node {
    const position = this.props.account.positions.get(
      this.props.account.symbol,
    );
    const wallet = this.props.account.wallets.get(
      this.props.account.symbol.slice(0, 3),
    );
    if (!position || !wallet) {
      return null;
    }
    const pnl = parseFloat(position.realised_pnl);
    const cumPnl = parseFloat(position.cum_realised_pnl);
    const availableBalance = parseFloat(wallet.available_balance);
    const walletBalance = parseFloat(wallet.wallet_balance);
    const fundingRate = this.props.account.instrumentInfo.funding_rate_e6 / 1e6;
    return (
      <Panel key={wallet.coin}>
        <img
          onClick={(e) =>
            this.props.updateTradingSettings({
              audioEnabled: !this.props.settings.audioEnabled,
            })
          }
          style={{
            float: "right",
            width: "1.0rem",
            height: "1.0rem",
            filter: "invert(70%)",
            marginLeft: "0.6rem",
            cursor: "pointer",
          }}
          src={this.props.settings.audioEnabled ? "volume.png" : "mute.png"}
        />
        <div
          onClick={(e) =>
            this.props.updateTradingSettings({
              chartEnabled: !this.props.settings.chartEnabled,
            })
          }
          style={{
            float: "right",
            width: "1.0rem",
            lineHeight: "1.0rem",
            filter: "invert(70%)",
            textAlign: "center",
            marginTop: "0.20rem",
            marginLeft: "0.6rem",
            fontSize: "1.1rem",
            cursor: "pointer",
          }}
        >
          📈
        </div>
        <div
          onClick={(e) =>
            this.props.updateTradingSettings({
              dangerousEnabled: !this.props.settings.dangerousEnabled,
            })
          }
          style={{
            float: "right",
            width: "1.0rem",
            lineHeight: "1.0rem",
            filter: "invert(70%)",
            textAlign: "center",
            marginTop: "0.20rem",
            marginLeft: "0.6rem",
            fontSize: "1.1rem",
            cursor: "pointer",
          }}
        >
          ⚠️
        </div>
        <div
          onClick={(e) =>
            this.props.updateTradingSettings({
              executionIndicators: !this.props.settings.executionIndicators,
            })
          }
          style={{
            float: "right",
            width: "1.0rem",
            lineHeight: "1.0rem",
            filter: "invert(70%)",
            textAlign: "center",
            marginTop: "-0.10rem",
            marginLeft: "0.6rem",
            fontSize: "1.0rem",
            cursor: "pointer",
          }}
        >
          ↕
        </div>
        <div style={styles.headerText}>Trading {this.props.account.symbol}</div>
        <div style={styles.rFlex}>
          <div style={styles.labelText}>Wallet Balance</div>
          <div style={styles.itemText}>
            <span style={{fontSize: "smaller", marginRight: 10}}>
              {(walletBalance * this.props.book.bestBuyAmount).toFixed(2)} USD
            </span>{" "}
            {walletBalance.toFixed(8)} {wallet.coin}
          </div>
        </div>
        <div style={styles.rFlex}>
          <div style={styles.labelText}>Available Balance</div>
          <div style={styles.itemText}>
            <span style={{fontSize: "smaller", marginRight: 10}}>
              {(availableBalance * this.props.book.bestBuyAmount).toFixed(2)}{" "}
              USD
            </span>{" "}
            {availableBalance.toFixed(8)} {wallet.coin}
          </div>
        </div>
        <div style={styles.rFlex}>
          <div style={styles.labelText}>{"Cum. Realized P&L"}</div>
          <div style={styles.itemText}>
            <span style={{fontSize: "smaller", marginRight: 10}}>
              {(cumPnl * this.props.book.bestBuyAmount).toFixed(2)} USD
            </span>
            {cumPnl > 0 ? " " : null}
            {cumPnl.toFixed(8)} {wallet.coin}
          </div>
        </div>
        <div style={styles.rFlex}>
          <div style={styles.labelText}>{"Daily Realized P&L"}</div>
          <div style={styles.itemText}>
            <span style={{fontSize: "smaller", marginRight: 10}}>
              {(pnl * this.props.book.bestBuyAmount).toFixed(2)} USD
            </span>
            {pnl > 0 ? " " : null}
            {pnl.toFixed(8)} {wallet.coin}
          </div>
        </div>
        <div style={styles.rFlex}>
          <div style={styles.labelText}>{"Funding Rate"}</div>
          <div style={styles.itemText}>
            <span style={{fontSize: "smaller", marginRight: 10}}>
              {/* {this.props.account.instrumentInfo.next_funding_time} */}
              {(
                position.size *
                fundingRate *
                (position.side === "Buy" ? -1 : 1)
              ).toFixed(2)}{" "}
              USD
            </span>
            {(fundingRate * 100).toFixed(4)}%
          </div>
        </div>
      </Panel>
    );
  }

  render(): React$Node {
    const position = this.props.account.positions.get(
      this.props.account.symbol,
    );
    return this.props.account.wallets.size > 0 ? (
      <div style={{...styles.rFlex, height: "100%"}}>
        {this.props.settings.chartOnlyEnabled ? null : (
          <div
            style={
              this.props.settings.chartEnabled ? {width: "32rem"} : {flex: 1}
            }
          >
            {this.renderWallet()}
            <AddStopOrderPanel />
            <PlaceOrderPanel />
            <CancelOrderPanel />
            {this.renderPositions()}
            <OrderBookPanel orderBook={this.props.book} />
            {this.renderOpenOrders()}
          </div>
        )}
        {this.props.settings.chartEnabled ? (
          <div style={styles.cFlex}>
            {position ? <TradingViewChart /> : null}
          </div>
        ) : null}
      </div>
    ) : (
      this.renderAPIKeyForm()
    );
  }
}

const styles = {
  cFlex: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  cFlexAuto: {
    display: "flex",
    flexDirection: "column",
  },
  rFlex: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
  },
  rFlexAuto: {
    display: "flex",
    flexDirection: "row",
  },
  labelText: {
    flex: 1,
  },
  itemText: {
    flex: 2,
    textAlign: "right",
    fontFamily: "monospace",
  },
  headerText: {
    fontSize: "1.2rem",
    marginBottom: "0.5rem",
  },
  orderButton: {
    padding: "3rem",
    fontSize: "2rem",
  },
};
