/**
 * @flow
 */
"use strict";

import * as React from "react";
import actions from "../actions";
import * as api2 from "../api2";
import * as types from "../actions/types";
import * as tvutils from "../lib/TradingViewUtils";
import * as colorUtils from "../lib/normalizeColor";
import * as bybitutils from "../lib/ByBitUtils";
import {ThemeContext} from "styled-components";

export type Props = {|
  tvWidget: any,
  order: types.bybitws.Order,
  position: ?types.bybitws.Position,
  replaceOrder: typeof actions.bybit.replaceOrder,
  cancelOrder: typeof actions.bybit.cancelOrder,
|};

export default class MyComponent extends React.PureComponent<Props> {
  static contextType: React$Context<any> = ThemeContext;
  line: any = null;

  getText(): string {
    return (
      this.props.order.side.toUpperCase() +
      " @ " +
      parseFloat(this.props.order.price).toFixed(1) +
      this.getPnL() +
      (this.props.order.leaves_qty !== this.props.order.qty ? " *PARTIAL*" : "")
    );
  }

  getPnL(): string {
    // https://help.bybit.com/hc/en-us/articles/900000404726 */}
    // Long | Unrealized P&L = Contract Qty x [(1/Avg Entry Price) - (1/Last Traded Price)]
    // Short | Unrealized P&L = Contract Qty x [(1/Last Traded Price) - (1/Avg Entry Price)]
    const entryPrice = parseFloat(this.props.position?.entry_price || "0");
    const orderPrice = parseFloat(this.props.order.price);
    let pnl =
      this.props.position?.side === "Buy" && this.props.order.side === "Sell"
        ? this.props.order.leaves_qty * (1 / entryPrice - 1 / orderPrice)
        : this.props.position?.side === "Sell" &&
          this.props.order.side === "Buy"
        ? this.props.order.leaves_qty * (1 / orderPrice - 1 / entryPrice)
        : 0;
    if (pnl) {
      pnl -=
        (this.props.order.leaves_qty * bybitutils.MAKER_FEE) / 100 / orderPrice;
      return ` PNL ${pnl.toFixed(8)} BTC ${(pnl * orderPrice).toFixed(2)} USD`;
    } else {
      return "";
    }
  }

  componentDidMount() {
    const color = colorUtils.rgba2rgb({
      background: this.context.bgColor04,
      foreground:
        this.props.order.side === "Buy"
          ? this.context.buyColor
          : this.context.sellColor,
    });
    this.line = this.props.tvWidget
      .chart()
      .createOrderLine()
      .setLineLength(100)
      .setText(this.getText())
      .setPrice(this.props.order.price)
      .setQuantity(this.props.order.leaves_qty)
      .onMove(() => {
        this.line.setText("Amending ...");
        const price = this.line.getPrice();
        this.props.replaceOrder(
          {
            symbol: this.props.order.symbol,
            order_id: this.props.order.order_id,
            order_link_id: this.props.order.order_link_id,
            p_r_price: price,
          },
          {
            ...this.props.order,
            price,
          },
        );
      })
      .onCancel(() => {
        this.line.setText("Cancelling ...");
        this.props.cancelOrder({
          symbol: this.props.order.symbol,
          order_id: this.props.order.order_id,
        });
      })
      .setLineStyle(2)
      .setLineWidth(1)
      .setLineColor(color)
      .setBodyBorderColor(color)
      .setBodyTextColor("#FFFFFF")
      .setBodyBackgroundColor("#1E1F25")
      .setQuantityTextColor("#FFFFFF")
      .setQuantityBorderColor(color)
      .setQuantityBackgroundColor(color)
      .setCancelButtonBorderColor(color)
      .setCancelButtonBackgroundColor("#1E1F25")
      .setCancelButtonIconColor("#FFFFFF");
  }

  componentWillUnmount() {
    if (this.line != null) {
      this.line.remove();
      this.line = null;
    }
  }

  componentDidUpdate() {
    if (this.line != null) {
      this.line
        .setText(this.getText())
        .setPrice(this.props.order.price)
        .setQuantity(this.props.order.leaves_qty);
    }
  }

  render(): React$Node {
    return null;
  }
}
