/**
 * @flow
 */

import {combineReducers} from "redux";
import type {Store, MyDispatch, Action} from "../actions/types.js";
import user from "./user";
import bybit from "./bybit";

type Reducer<S, A> = (state: S, action: A) => S;
type CombinedReducer<S, A> = (state: $Shape<S>, action: A) => S;

const reducers: CombinedReducer<Store, Action> = combineReducers({
  user,
  bybit,
});

function rootReducer(
  state: Store,
  action: Action,
  ...args: *
): Store {
  // agregar un root reducer de computo de datos.
  return reducers(state, action, ...args);
}

export default rootReducer;