/**
 * @flow
 */

import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import type {Store, MyDispatch, Action} from "../actions/types.js";
import actions from "../actions";
import * as api2 from "../api2";
import MyComponent, {type Props} from "../components/OrderGroupSelector";

type OwnProps = {|
  groupCount: number,
|};

const connector = connect<Props, OwnProps, _, _, _, _>(
  (store: Store, ownProps: OwnProps) => ({
    currentGroup: store.user.tradingSettings.orderGroup,
    groupCount: ownProps.groupCount,
  }),
  (dispatch: MyDispatch, ownProps: OwnProps) => ({
    updateTradingSettings: bindActionCreators(
      actions.user.updateTradingSettings,
      dispatch,
    ),
  }),
);

const Connected: * = connector(MyComponent);
export default Connected;

function test(props: OwnProps) {
  return <Connected {...props} />;
}
