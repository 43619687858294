/**
 * @flow
 */

import React from "react";
import {Provider} from "react-redux";
import {createLogger} from "redux-logger";
import thunk from "redux-thunk";
import {applyMiddleware, createStore} from "redux";
import createSagaMiddleware from "redux-saga";

type ConfigureStore = (() => void) => Object;

type Props = {
  configureStore: ConfigureStore,
  App: any,
  Loading: any,
};

type State = {
  isLoading: boolean,
};

const StoreContainer: {|store: ?Object|} = {
  store: null,
};

class Root extends React.Component<Props, State> {
  constructor(props) {
    super();
    let isLoading = !StoreContainer.store;
    this.state = {
      isLoading,
    };
  }

  componentDidMount() {
    // Esto lo haciamos en constructor pero el setState se disparaba antes
    // del mount y era un noop, habiamos hecho un hack con setTimeout pero
    // poniendolo aca es innecesario.
    if (this.state.isLoading) {
      // Convertimos store en un singleton.
      // Esto fue porque Root se desmonta cuando tenemos un foreground
      // service y se mata la app. Al volver a entrar, el entorno ya esta
      // inicializado y al disparar configureStore terminamos con sagas por
      // duplicadas y quien sabe que otros problemas.
      StoreContainer.store = this.props.configureStore(() => {
        this.setState({isLoading: false});
      });
    }
  }

  render() {
    const {App, Loading} = this.props;
    if (this.state.isLoading) {
      return Loading;
    }
    return (
      <Provider store={StoreContainer.store}>
        <App />
      </Provider>
    );
  }
}

const skipTypes = [
  "BYBIT_ORDER_BOOK_DELTA_RECEIVED",
  "BYBIT_PONG_RECEIVED",
  "BYBIT_INSTRUMENT_INFO_DELTA_RECEIVED",
];

export default function configure(params: {
  App: Class<React.Component<*, *>>,
  Loading: Class<React.Component<*, *>>,
  reducers: *,
  sagas: *,
}): () => React$Element<*> {
  const isDebugging =
    !process.env.NODE_ENV || process.env.NODE_ENV === "development";

  const logger = createLogger({
    predicate: (getState, action) =>
      isDebugging && (!action || skipTypes.indexOf(action.type) === -1),
    collapsed: true,
    duration: true,
  });
  const sagaMiddleware = createSagaMiddleware();
  const createStoreWithMiddleware = applyMiddleware(
    sagaMiddleware,
    thunk,
    logger,
  )(createStore);
  const store = createStoreWithMiddleware(params.reducers);
  sagaMiddleware.run(params.sagas);

  const configureStore = (onComplete: ?() => void) => {
    onComplete && onComplete(); // No tenemos persist aun, lo disparamos a mano
    return store;
  };

  return () => (
    <Root
      App={params.App}
      Loading={params.Loading}
      configureStore={configureStore}
    />
  );
}
